.custom-icon {
 text-align: center;
}

.custom-icon::before {
 line-height: 1.7 !important;
}

.hdr-main-content-bc-custom {
 background: var(--header);
}

.logo-container-custom {
 display: flex;
 flex-direction: row;
 align-items: center;
}
