.custom-slider-container {
 margin-top: 5px !important;
}

.custom-counter {
 z-index: 200 !important;
 display: flex;
 flex-direction: row;
}

.carousel-color-text {
 color: rgba(255, 255, 255, 0.848);
 font-size: 10px;
 margin-left: 1px;
 margin-right: 1px;
}
