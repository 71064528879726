.custom-layout {
  overflow-y: auto;
}

.test {
  background-color: red;
  width: 100%;
  height: 50px;
  position: absolute;
  z-index: 1000;
}