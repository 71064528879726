a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canva s,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}
:focus {
  outline: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}
input {
  color: inherit;
  border: 0;
  box-sizing: border-box;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}
input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
}
input::-webkit-input-placeholder {
  color: inherit;
}
input::-moz-placeholder {
  color: inherit;
}
input::-ms-input-placeholder {
  color: inherit;
}
input::placeholder {
  color: inherit;
}
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden] {
  display: none;
}
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
a {
  text-decoration: none;
}
a:active,
a:hover {
  outline: 0;
}
a:visited {
  /* color: inherit; */
}
img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}
figure,
form {
  margin: 0;
}
fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
  white-space: normal;
  *margin-left: -7px;
}
button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}
button,
input {
  line-height: normal;
}
button,
select {
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  *overflow: visible;
}
button {
  border: 0;
}
button[disabled],
html input[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  *height: 13px;
  *width: 13px;
}
input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button,
html,
input,
select,
textarea {
  color: #222;
}
::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}
::selection {
  background: #b3d4fc;
  text-shadow: none;
}
img {
  vertical-align: middle;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
textarea {
  resize: vertical;
}
html {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  color: #5b5b5b;
}
body,
html {
  overflow-x: hidden;
}
text {
  font-family: Rajdhani, Roboto Condensed, sans-serif;
}
.event-text-icon {
  width: 15px;
  height: 25px;
  display: block;
  margin-left: 4px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
}
.event-text-icon.yellow-card-img {
  /* background-image: url(yellow-card-event.fbfcbdd832ef0d47529e.svg); */
}
.event-text-icon.red-card-img {
  /* background-image: url(red-card-event.5d66a897cd7c0d51084f.svg); */
}
.event-text-icon.injury-img {
  /* background-image: url(injury.a42fde24b70d1f8f6a7c.svg); */
  width: 20px;
  height: 18px;
}
.event-text-icon.substitution-img {
  /* background-image: url(substitution.121db0b019a2f27f7b0c.svg); */
}
.event-text-icon.basketball-foul-img {
  width: 25px;
  /* background-image: url(foul.43627f3a50dfd1a05676.svg); */
}
.event-text-icon.basketball-time-out-img {
  width: 25px;
  /* background-image: url(time-out.7f0e99d1c81149d0efa7.svg); */
}
.event-text-icon.kick-off {
  width: 25px;
  /* background-image: url(whistle.9e2dbeefb14a853ad37f.svg); */
}
