$backgroud-color: #0b0005;
$backgroud-color-panel: rgba(50, 50, 50);
$backgroud-color-navigation: rgba(61, 61, 61);
$backgroud-color-panel-burger: rgba(71, 71, 71);
$backgroud-color-black: #000000;
$backgroud-color-hero: #fae001;

$input-bc: rgba(27, 27, 27);
$backgroud-color-back: #0b0005;

$white-color: rgba(255, 255, 255);
$white-color-01: rgba(255, 255, 255, 0.1);
$white-color-05: rgba(255, 255, 255, 0.5);
$white-color-06: rgba(255, 255, 255, 0.6);
$white-color-07: rgba(255, 255, 255, 0.7);
$white-color-08: rgba(255, 255, 255, 0.8);
$white-color-09: rgba(255, 255, 255, 0.9);
$white-color-005: rgba(255, 255, 255, 0.05);
$black-color: #000000;
$yellow-color: rgba(249, 223, 50);

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
