.promotionsList-bc {
  display: grid;
  grid-gap: 10px;
}
.promotionsListEl-bc {
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}
.promotionsArticleThumbnail-bc {
  display: flex;
  width: 100%;
  height: auto;
  opacity: 0;
  border-radius: 4px 4px 0 0;
  -webkit-animation: promo-img 0.24s 0.1s forwards;
  animation: promo-img 0.24s 0.1s forwards;
}
@-webkit-keyframes promo-img {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes promo-img {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.promotions-article-title {
  height: 34px;
  margin-top: 1px;
  padding: 0 10px;
  color: var(--b-sc);
  background: rgba(var(--b-sc-rgb), 0.1);
  font-size: 12px;
  line-height: 34px;
}
.prevent-prevent-action {
  opacity: 0.85;
  pointer-events: none;
}
.promotions-opt-button {
  position: absolute;
  top: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
  min-width: 85px;
  z-index: 9;
}
[dir="ltr"] .promotions-opt-button {
  right: 10px;
}
[dir="rtl"] .promotions-opt-button {
  left: 10px;
}
.promotion-details-header {
  position: relative;
}
.promotion-details-img {
  width: 100%;
  object-fit: cover;
}
.promotionDetSocList-bc {
  display: flex;
  justify-content: flex-end;
}
.promotionDetSoc-bc {
  display: flex;
  justify-content: center;
  align-items: center;
}
.promotionDetSoc-bc i {
  color: rgba(var(--b-sc-rgb), 0.7);
  font-size: 25px;
}
.promotionDetSoc-bc + .promotionDetSoc-bc {
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
}
.promotion-details-info {
  font-size: 14px;
  line-height: 1.2;
  color: rgba(var(--b-sc-rgb), 0.6);
  -webkit-user-select: text;
  user-select: text;
}
.promotion-details-info h3 {
  text-align: start;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 400;
  padding-bottom: 15px;
}
.promotion-details-info p {
  padding: 10px 0;
  color: rgba(var(--b-sc-rgb), 0.9);
  line-height: 1.3;
}
[lang="ka"] .promotion-details-info p {
  text-transform: lowercase;
}
.promotion-details-info p img {
  max-width: 100%;
  object-fit: contain;
  border-radius: 4px;
}
.promotion-details-info a {
  color: var(--hero);
  text-decoration: underline;
}
.promotion-details-info iframe {
  width: 100%;
}
.promotion-details-info table {
  display: flex;
  width: 100% !important;
  border-spacing: 1px;
  border-collapse: initial !important;
}
.promotion-details-info table tbody {
  display: table;
  white-space: nowrap;
}
.promotion-details-info table tr {
  background: rgba(var(--b-sc-rgb), 0.1);
}
.promotion-details-info table tr:first-child td:first-child {
  border-start-start-radius: 4px;
}
.promotion-details-info table tr:first-child td:last-child {
  border-start-end-radius: 4px;
}
.promotion-details-info table tr:last-child td:first-child {
  border-end-start-radius: 4px;
}
.promotion-details-info table tr:last-child td:last-child {
  border-end-end-radius: 4px;
}
.promotion-details-info td {
  vertical-align: middle;
  height: 32px;
  padding: 0 5px;
}
.promotion-details-info td p {
  word-break: break-all;
}
.promotion-details-info ol,
.promotion-details-info ul {
  list-style: revert;
  list-style-position: inside;
}
.promotion-details-info ol > li + li,
.promotion-details-info ul > li + li {
  margin: 10px 0;
}
.current-day-1:after {
  content: "1";
}
.current-day-2:after {
  content: "2";
}
.current-day-3:after {
  content: "3";
}
.current-day-4:after {
  content: "4";
}
.current-day-5:after {
  content: "5";
}
.current-day-6:after {
  content: "6";
}
.current-day-7:after {
  content: "7";
}
.current-day-8:after {
  content: "8";
}
.current-day-9:after {
  content: "9";
}
.current-day-10:after {
  content: "10";
}
.current-day-11:after {
  content: "11";
}
.current-day-12:after {
  content: "12";
}
.current-day-13:after {
  content: "13";
}
.current-day-14:after {
  content: "14";
}
.current-day-15:after {
  content: "15";
}
.current-day-16:after {
  content: "16";
}
.current-day-17:after {
  content: "17";
}
.current-day-18:after {
  content: "18";
}
.current-day-19:after {
  content: "19";
}
.current-day-20:after {
  content: "20";
}
.current-day-21:after {
  content: "21";
}
.current-day-22:after {
  content: "22";
}
.current-day-23:after {
  content: "23";
}
.current-day-24:after {
  content: "24";
}
.current-day-25:after {
  content: "25";
}
.current-day-26:after {
  content: "26";
}
.current-day-27:after {
  content: "27";
}
.current-day-28:after {
  content: "28";
}
.current-day-29:after {
  content: "29";
}
.current-day-30:after {
  content: "30";
}
.current-day-31:after {
  content: "31";
}
.promotionsList-bc {
  grid-template-columns: repeat(auto-fill, minmax(345px, 1fr));
  padding: 10px 0 20px;
}
.promotionsListEl-bc {
  cursor: pointer;
}
.promotionsListEl-bc:hover .promotionsArticleThumbnail-bc {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
.promotionsArticleThumbnail-bc {
  transition: 0.24s;
}
.promotion-details-popup.windowed .popup-inner-bc {
  width: 100%;
  max-width: 1340px;
}
.promotion-details-popup.poker-popup.windowed .popup-inner-bc {
  padding-top: 49px;
  height: 83vh;
}
.promotion-details-popup:not(.poker-popup) .promotion-details-info {
  max-height: 83vh;
}
.poker-popup .status-popup-content-w-bc {
  width: 100%;
}
.poker-popup .casino-popup-title {
  position: relative;
  text-align: start;
  -webkit-padding-start: 10px;
  padding-inline-start: 10px;
  z-index: 1002;
}
.poker-popup .promotion-details-container {
  padding-top: 100px;
}
.poker-popup .popup-inner-bc {
  height: 83vh;
}
.promotion-details-container {
  display: flex;
  padding: 15px;
  height: 100%;
}
.promotion-details-header {
  flex-shrink: 0;
  align-self: flex-start;
  width: 400px;
}
.promotion-details-img {
  border-radius: 4px;
}
.promotion-details-info {
  flex: auto;
  -webkit-padding-start: 20px;
  padding-inline-start: 20px;
  will-change: scroll-position;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.promotion-details-info table {
  overflow-y: hidden;
  overflow-x: auto;
}
