.results-container-bc {
  padding-top: 15px;
}
.results-filter-bc {
  margin-bottom: 20px;
}
.results-block-bc {
  display: flex;
  flex-direction: column;
  padding: 0 5px 5px;
  background: rgba(var(--b-sc-rgb), 0.05);
}
.results-block-bc + .results-block-bc {
  margin-top: 5px;
}
.results-info-bc {
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
  background: rgba(var(--b-sc-rgb), 0.1);
  border-radius: 4px 4px 0 0;
}
.results-teams-bc {
  display: flex;
}
.results-teams-bc + .results-teams-bc {
  margin-top: 5px;
}
.results-teams-name-bc {
  flex: auto;
  font-size: 11px;
  line-height: 13px;
  color: rgba(var(--b-sc-rgb), 0.9);
}
.results-teams-score-bc {
  flex-shrink: 0;
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: var(--oc-2);
}
.results-details-bc {
  margin-top: 8px;
  font-size: 10px;
  line-height: 12px;
  color: rgba(var(--b-sc-rgb), 0.5);
}
[dir="rtl"] .results-details-bc {
  direction: ltr;
  text-align: right;
}
.results-footer-bc {
  display: flex;
  align-items: center;
  height: 24px;
  margin-top: 1px;
  padding: 0 5px;
  background: rgba(var(--b-sc-rgb), 0.1);
  border-radius: 0 0 4px 4px;
}
.results-footer-info-bc {
  flex: auto;
  display: flex;
  align-items: center;
}
.results-footer-icon-bc {
  font-size: 14px;
  -webkit-margin-end: 2px;
  margin-inline-end: 2px;
  color: rgba(var(--b-sc-rgb), 0.5);
}
.results-footer-date-bc {
  font-size: 10px;
  padding: 0 4px;
  color: rgba(var(--b-sc-rgb), 0.5);
}
.results-footer-date-bc + .results-footer-date-bc {
  -webkit-border-start: 1px solid var(--b);
  border-inline-start: 1px solid var(--b);
}
.results-footer-view-bc {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
  color: rgba(var(--b-sc-rgb), 0.7);
}
.results-footer-view-text-bc {
  font-size: 12px;
  -webkit-margin-end: 8px;
  margin-inline-end: 8px;
}
.results-footer-view-icon-bc {
  font-size: 10px;
}
[dir="rtl"] .results-footer-view-icon-bc:before {
  content: "оЊ”";
}
.results-banner-wrapper-bc {
  padding: 8px 10px 30px;
}
.results-banner-bc {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 4px;
  position: relative;
}
.results-banner-bc:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}
.results-banner-teams-bc {
  display: flex;
  align-items: center;
  color: #fff;
  position: relative;
}
.results-banner-teams-bc + .results-banner-teams-bc {
  margin-top: 6px;
}
.results-banner-teams-name-bc {
  flex: auto;
  font-size: 15px;
}
.results-banner-teams-score-bc {
  flex-shrink: 0;
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
  font-size: 16px;
  font-weight: 500;
}
.results-banner-details-bc {
  color: #b3b3b3;
  margin-top: 5px;
  font-size: 11px;
  position: relative;
}
.results-game-details-bc {
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.results-game-details-header-bc {
  display: flex;
  align-items: center;
  height: 34px;
  padding: 0 10px;
  background: rgba(var(--b-sc-rgb), 0.1);
  border-radius: 4px 4px 0 0;
}
.results-game-details-header-bc:only-child {
  border-radius: 4px;
}
.results-game-details-title-bc {
  flex: auto;
  font-size: 12px;
  color: var(--b-sc);
}
.results-game-details-icon-arrow-bc {
  flex-shrink: 0;
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
  color: rgba(var(--b-sc-rgb), 0.8);
  font-size: 11px;
}
.results-game-details-icon-arrow-bc.hidden {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
.results-game-details-events-bc {
  display: flex;
  align-items: center;
  height: 34px;
  margin-top: 1px;
  padding: 0 10px;
  color: rgba(var(--b-sc-rgb), 0.5);
  background: rgba(var(--b-sc-rgb), 0.05);
  font-size: 12px;
}
.results-game-details-events-bc:last-child {
  border-radius: 0 0 4px 4px;
}
.results-filter-bc {
  margin-top: 10px;
}
.results-container-bc {
  flex-direction: column;
  padding-bottom: 10px;
}
.results-container-bc,
.results-section {
  display: flex;
  height: 100%;
}
.results-section-wrapper {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
}
.results-section-wrapper > div {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  -webkit-padding-end: 2px;
  padding-inline-end: 2px;
  will-change: scroll-position;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.results-section-wrapper + .results-section-wrapper {
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
}
.results-block-bc,
.results-game-details-bc {
  cursor: pointer;
}
.virtual-sport-horizontal-slider-bc .popup-holder-bc.windowed .popup-inner-bc {
  width: 980px;
  height: 83vh;
  padding: 20px 10px 0;
}
.live-calendar {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 10px;
}
.live-calendar-market-scroll {
  height: 100%;
  position: relative;
}
.live-calendar-market-bc {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-padding-end: 2px;
  padding-inline-end: 2px;
  will-change: scroll-position;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.live-calendar-filters-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.live-calendar-filters-wrapper .multi-select-bc {
  flex: 30% 1;
}
.live-calendar-filters-wrapper .time-filters-bc {
  width: 70%;
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
  padding: 12px;
  background: rgba(var(--b-sc-rgb), 0.05);
  border-radius: 4px;
}
.live-calendar-filters-wrapper .time-filters-content {
  justify-content: flex-end;
}
.time-filters-content {
  flex: auto;
  display: flex;
}
.time-filter-button-bc {
  white-space: nowrap;
}
.time-filter-button-bc.btn.active {
  color: rgba(var(--b-rgb), 0.9);
  background: var(--b-sc);
  border-color: var(--b-sc);
}
.time-filters-bc {
  flex-shrink: 0;
  display: flex;
  width: 100%;
}
.time-filters-bc .time-filters-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(94px, 1fr));
  grid-gap: 5px;
}
.notification-loader .vivaro-loader svg {
  max-height: 13px;
}
.popup-holder-bc.bet-builder-top.windowed.info {
  padding: 0;
}
.popup-holder-bc.bet-builder-top.windowed.info .popup-inner-bc {
  width: 100%;
  height: 100%;
}
.popup-holder-bc.bet-builder-top.windowed.info
  .popup-inner-bc
  .status-popup-content-w-bc {
  padding: 0;
}
.bet-builder-top .status-popup-content-w-bc {
  width: 100%;
  height: 100%;
}
.betBuilder-popup {
  position: relative;
  background: #fff;
  height: 100%;
}
.betBuilder-popup iframe {
  width: 100%;
  height: calc(100% - 40px);
}
.betBuilder-popup .e-p-close-icon-bc {
  color: rgba(63, 63, 63, 0.8);
  width: 14px;
  height: 14px;
  font-size: 14px;
  line-height: 14px;
  top: 14px;
  transition: color 0.24s;
}
[dir="ltr"] .betBuilder-popup .e-p-close-icon-bc {
  right: 20px;
}
[dir="rtl"] .betBuilder-popup .e-p-close-icon-bc {
  left: 20px;
}
.betBuilder-popup .e-p-close-icon-bc:hover {
  color: #3f3f3f;
}
.betBuilder-popup .betBuilder-popup-match {
  position: relative;
  height: 40px;
  -webkit-padding-start: 20px;
  padding-inline-start: 20px;
  background: #efefef;
  box-shadow: 1px 1px 4px rgba(63, 63, 63, 0.4);
  z-index: 1;
}
.betBuilder-popup .betBuilder-popup-match span {
  display: block;
  font-size: 16px;
  font-weight: 700;
  line-height: 40px;
  color: #3f3f3f;
  max-width: 90%;
}
.betBuilder-popup.betslip-open:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
}
.betBuilder-betslip-content {
  width: 100%;
  position: absolute;
  bottom: 0;
  background: #efefef;
}
.betBuilder-betslip-content .bs-favorite-remove {
  display: flex;
  align-items: center;
  min-width: 0;
}
.betBuilder-betslip-content .betslip-remove-all {
  color: #595959;
  text-decoration: underline;
}
.betBuilder-betslip-content .bs-bet-i-b-stake-bc .bs-bet-i-b-s-i-block-bc {
  background: #ddd;
  color: #595959;
  border-color: #ddd;
}
.betBuilder-betslip-content
  .bs-bet-i-b-stake-bc
  .bs-bet-i-b-s-i-block-bc
  > span:after {
  background: #595959;
}
.betBuilder-betslip-content .bs-bet-i-b-stake-bc + .bs-numbers-bc {
  margin-top: 5px;
}
.betBuilder-betslip-content .bs-bet-i-b-title-bc.t-2,
.betBuilder-betslip-content .quickBetMsgText-bc {
  color: #595959;
}
.betBuilder-betslip-content .bet-slip-info-link {
  -webkit-margin-start: 3px;
  margin-inline-start: 3px;
}
.betBuilder-odd-remove {
  display: flex;
  align-items: center;
}
.betBuilder-odd-remove .bs-remove-all-bc {
  flex: auto;
  justify-content: flex-start;
  margin: 0;
  -webkit-margin-end: 5px;
  margin-inline-end: 5px;
  padding: 0;
}
.betBuilder-odds {
  display: flex;
  align-items: center;
  font-size: 12px;
  flex-shrink: 0;
}
.betBuilder-odds p {
  flex: auto;
  color: #595959;
}
.betBuilder-odds span {
  -webkit-margin-start: 4px;
  margin-inline-start: 4px;
  flex-shrink: 0;
  color: #faab2e;
}
.bet-builder-btn {
  height: 44px;
  font-size: 16px;
  margin-top: 8px;
  color: #000;
  background: #faab2e;
}
.bet-builder-btn:disabled {
  color: #8d8d8d;
  background: #cacaca;
}
.bet-builder-btn + .bs-info-massages {
  margin-top: 5px;
}
.popup-holder-bc.bet-builder-top.windowed.info .popup-inner-bc {
  max-width: 975px;
}
.betBuilder-betslip-content {
  display: flex;
  padding: 24px 24px 20px;
}
.betBuilder-betslip-content > div {
  width: 100%;
}
.betBuilder-betslip-content .bs-bet-i-b-stake-bc {
  height: 44px;
}
.betBuilder-betslip-content
  .bs-bet-i-b-stake-bc
  .bs-bet-i-b-s-i-bc::-webkit-input-placeholder {
  color: #595959;
}
.betBuilder-betslip-content
  .bs-bet-i-b-stake-bc
  .bs-bet-i-b-s-i-bc::placeholder {
  color: #595959;
}
.betBuilder-betslip-content .bs-bet-i-b-stake-bc .bs-bet-i-b-s-i-bc {
  color: #595959;
}
.betBuilder-betslip-content .bs-favorite-remove:hover .betslip-remove-all {
  color: #343434;
}
.betBuilder-section {
  -webkit-padding-start: 54px;
  padding-inline-start: 54px;
}
.betBuilder-text {
  -webkit-border-end: 2px solid #d8d8d8;
  border-inline-end: 2px solid #d8d8d8;
}
.betBuilder-text > p {
  font-size: 16px;
  color: #4d4d4d;
  font-weight: 600;
}
.betBuilder-text-content {
  font-size: 14px;
  letter-spacing: 0.47px;
  margin-top: 18px;
  -webkit-padding-end: 20px;
  padding-inline-end: 20px;
  height: 140px;
  will-change: scroll-position;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.bet-builder-btn {
  max-width: 100%;
}
.bet-builder-btn:hover {
  background: #db9525;
}
.sp-sub-list-bc {
  transition: all 0.24s;
}
.sp-sub-list-bc.active {
  max-height: 15000px;
}
.sp-sub-list-bc.active > .sp-s-l-head-bc .sp-s-l-h-arrow-icon-bc:before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sp-sub-list-bc.active > .sp-s-l-b-content-bc {
  opacity: 1;
  max-height: 14000px;
  pointer-events: auto;
  will-change: scroll-position;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.sp-sub-list-bc .sp-s-l-h-title-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: auto;
  height: 100%;
}
[dir="rtl"]
  .sp-sub-list-bc
  .sp-s-l-h-title-content
  .sp-s-l-h-title-bc
  + .sp-s-l-h-title-bc {
  margin-top: -4px;
}
.sp-s-l-head-bc {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sp-s-l-h-flag-bc {
  flex-shrink: 0;
}
.sp-s-l-h-flag-bc,
.sp-s-l-h-title-bc {
  -webkit-margin-end: 10px;
  margin-inline-end: 10px;
}
.sp-s-l-h-title-bc {
  text-transform: capitalize;
}
.sp-s-l-b-c-i-g-league-c-bc {
  flex-shrink: 0;
}
.sp-s-l-h-arrow-icon-bc {
  flex-shrink: 0;
  color: rgba(var(--b-sc-rgb), 0.7);
  font-size: 11px;
}
.sp-s-l-b-content-bc {
  max-height: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.24s;
  overflow: hidden;
}
.sp-s-l-b-content-bc:empty {
  display: none;
}
.sp-s-l-b-c-i-title-bc {
  flex: auto;
  margin: 0 10px;
  color: rgba(var(--b-sc-rgb), 0.9);
  font-size: 13px;
}
.sp-s-l-b-c-i-title-bc + .sp-s-l-h-flag-bc {
  -webkit-margin-end: 5px;
  margin-inline-end: 5px;
}
.sp-s-l-b-c-i-g-count-bc {
  flex-shrink: 0;
  color: rgba(var(--b-sc-rgb), 0.7);
  font-size: 13px;
  min-width: 15px;
  text-align: end;
}
.competition-ico-arr-bc {
  flex-shrink: 0;
  width: 11px;
  -webkit-margin-start: 12px;
  margin-inline-start: 12px;
  color: rgba(var(--b-sc-rgb), 0.6);
  font-size: 11px;
}
[dir="rtl"] .competition-ico-arr-bc:before {
  content: "оЊ”";
}
.pp-sport-list-holder-bc {
  position: relative;
  height: 100%;
  will-change: scroll-position;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.e-sports .left-menu-scroll > .sport-search-bc {
  position: -webkit-sticky;
  position: sticky;
  padding: 10px;
  height: 56px;
  top: 0;
  margin-bottom: 2px;
  background: var(--b);
  border-radius: 4px;
  z-index: 9;
}
.e-sports .left-menu-scroll > .sport-search-bc:after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(var(--b-sc-rgb), 0.05);
  pointer-events: none;
}
[dir="ltr"] .e-sports .left-menu-scroll > .sport-search-bc:after {
  left: 0;
}
[dir="rtl"] .e-sports .left-menu-scroll > .sport-search-bc:after {
  right: 0;
}
.e-sports .left-menu-scroll > .sport-search-bc .ss-icon-holder-bc {
  bottom: 0;
  margin: auto;
}
[dir="ltr"] .e-sports .left-menu-scroll > .sport-search-bc .ss-icon-holder-bc {
  right: 10px;
}
[dir="rtl"] .e-sports .left-menu-scroll > .sport-search-bc .ss-icon-holder-bc {
  left: 10px;
}
.e-sports .left-menu-scroll > .sport-search-bc .sport-search-result-bc {
  width: calc(100% - 20px);
  top: 48px;
}
[dir="ltr"]
  .e-sports
  .left-menu-scroll
  > .sport-search-bc
  .sport-search-result-bc {
  left: 10px;
}
[dir="rtl"]
  .e-sports
  .left-menu-scroll
  > .sport-search-bc
  .sport-search-result-bc {
  right: 10px;
}
.e-sports .left-menu-scroll > .sp-sub-list-bc.active > .sp-s-l-head-bc {
  top: 56px;
}
.e-sports .left-menu-scroll > .sp-sub-list-bc.active .sp-s-l-b-content-bc {
  will-change: scroll-position;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.left-menu-scroll {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  -webkit-padding-end: 2px;
  padding-inline-end: 2px;
}
.left-menu-scroll > .sp-sub-list-bc {
  background: rgba(var(--b-sc-rgb), 0.1);
  border-radius: 4px;
}
.left-menu-scroll > .sp-sub-list-bc > .sp-s-l-head-bc .sp-s-l-h-title-bc {
  color: var(--b-sc);
}
.left-menu-scroll > .sp-sub-list-bc > .sp-s-l-b-content-bc > div:not([class]) {
  display: flex;
}
.left-menu-scroll
  > .sp-sub-list-bc
  > .sp-s-l-b-content-bc
  > div:not([class])
  .bs-favorite-remove-bc {
  align-items: center;
  border-top: 1px solid rgba(var(--b-sc-rgb), 0.1);
}
.left-menu-scroll > .sp-sub-list-bc > .sp-s-l-b-content-bc > .sp-sub-list-bc {
  padding: 0 5px;
  box-shadow: inset 0 1px 0 0 rgba(var(--b-sc-rgb), 0.1);
}
.left-menu-scroll
  > .sp-sub-list-bc
  > .sp-s-l-b-content-bc
  > .sp-sub-list-bc
  > .sp-s-l-head-bc {
  padding: 0 5px;
}
.left-menu-scroll
  > .sp-sub-list-bc
  > .sp-s-l-b-content-bc
  > .sp-sub-list-bc
  > .sp-s-l-head-bc
  .sp-s-l-h-title-bc {
  color: rgba(var(--b-sc-rgb), 0.8);
}
.left-menu-scroll
  > .sp-sub-list-bc
  > .sp-s-l-b-content-bc
  > .sp-sub-list-bc.active {
  padding-bottom: 5px;
  background: rgba(var(--b-sc-rgb), 0.1);
  box-shadow: inset 0 1px 0 0 transparent;
}
.left-menu-scroll > .sp-sub-list-bc + .sp-sub-list-bc,
.left-menu-scroll
  > .sp-sub-list-bc
  > .sp-s-l-b-content-bc
  .sp-s-l-b-content-bc
  > .sp-sub-list-bc
  + .sp-sub-list-bc {
  margin-top: 2px;
}
.left-menu-scroll > .sp-sub-list-bc > .sp-s-l-b-content-bc > .sp-sub-list-bc {
  background: rgba(var(--b-sc-rgb), 0.05);
}
.left-menu-scroll
  > .sp-sub-list-bc
  > .sp-s-l-b-content-bc
  > .sp-sub-list-bc
  .sp-s-l-b-content-bc {
  padding: 5px;
  background: var(--b);
}
.left-menu-scroll
  > .sp-sub-list-bc
  > .sp-s-l-b-content-bc
  > .sp-sub-list-bc
  .sp-sub-list-bc
  > .sp-s-l-head-bc {
  background: rgba(var(--b-sc-rgb), 0.15);
}
.left-menu-scroll
  > .sp-sub-list-bc
  > .sp-s-l-b-content-bc
  > .sp-sub-list-bc
  .sp-sub-list-bc
  > .sp-s-l-head-bc
  .sp-s-l-h-title-bc {
  color: rgba(var(--b-sc-rgb), 0.8);
}
.left-menu-scroll
  > .sp-sub-list-bc
  > .sp-s-l-b-content-bc
  > .sp-sub-list-bc
  .sp-sub-list-bc.selected
  > .sp-s-l-head-bc,
.left-menu-scroll
  > .sp-sub-list-bc
  > .sp-s-l-b-content-bc
  > .sp-sub-list-bc
  .sp-sub-list-bc:hover
  > .sp-s-l-head-bc {
  background: rgba(var(--b-sc-rgb), 0.2);
}
.left-menu-scroll > .sp-sub-list-bc.active > .sp-s-l-head-bc {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: var(--b);
  box-shadow: inset 0 0 0 18px rgba(var(--b-sc-rgb), 0.1);
  z-index: 1;
}
.sp-sub-list-bc {
  max-height: 34px;
}
.sp-sub-list-bc .sp-sub-list-bc .sp-s-l-b-content-bc {
  border-radius: 4px;
}
.sp-sub-list-bc .form-control-bc {
  width: 100%;
  margin-bottom: 5px;
  padding: 5px 5px 0;
  border-top: 1px solid rgba(var(--b-sc-rgb), 0.1);
}
.sp-sub-list-bc .form-control-select-bc {
  width: auto;
}
.sp-sub-list-bc .multi-select-label-bc {
  -webkit-margin-start: 5px;
  margin-inline-start: 5px;
}
.sp-s-l-head-bc {
  height: 34px;
  padding: 0 10px;
  cursor: pointer;
  transition: background 0.24s;
  border-radius: 4px;
}
.sp-s-l-h-flag-bc {
  width: 20px;
  text-align: center;
  font-size: 18px;
}
.sp-s-l-h-flag-bc.flag-bc {
  width: 18px;
  height: 12px;
}
.sp-s-l-h-title-bc {
  font-size: 12px;
}
.sp-s-l-b-c-i-g-league-c-bc {
  color: rgba(var(--b-sc-rgb), 0.5);
  font-size: 12px;
}
.sp-s-l-h-arrow-icon-bc {
  width: 24px;
  height: 24px;
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
  text-align: center;
  border-radius: 4px;
  line-height: 23px;
  transition: color 0.24s, background 0.24s;
}
.sp-s-l-h-arrow-icon-bc:before {
  display: block;
  transition: -webkit-transform 0.24s;
  transition: transform 0.24s;
  transition: transform 0.24s, -webkit-transform 0.24s;
}
[dir="rtl"] .sp-s-l-h-arrow-icon-bc.bc-i-small-arrow-right {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sp-s-l-b-c-item-bc {
  display: flex;
  align-items: center;
  height: 35px;
  -webkit-padding-start: 8px;
  padding-inline-start: 8px;
  -webkit-padding-end: 10px;
  padding-inline-end: 10px;
  background: rgba(var(--b-sc-rgb), 0.1);
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.24s;
}
.sp-s-l-b-c-item-bc + .sp-s-l-b-c-item-bc {
  margin-top: 2px;
}
.sp-s-l-b-c-item-bc:last-child {
  margin-bottom: 3px;
}
.sp-s-l-b-c-item-bc.active,
.sp-s-l-b-c-item-bc:hover {
  background: rgba(var(--b-sc-rgb), 0.2);
}
.market-game-section {
  padding: 6px 10px 10px;
  background: rgba(var(--b-sc-rgb), 0.15);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  transition: background 0.1s, box-shadow 0.1s;
}
.market-game-section:not(.active) {
  cursor: pointer;
}
.market-game-section.active,
.market-game-section:hover {
  background: rgba(var(--b-sc-rgb), 0.2);
}
.market-game-section:before {
  content: "";
  position: absolute;
  top: 0;
  width: 2px;
  height: 100%;
}
[dir="ltr"] .market-game-section:before {
  left: 0;
}
[dir="rtl"] .market-game-section:before {
  right: 0;
}
.market-game-section + .market-game-section {
  margin-top: 2px;
}
.market-game-team {
  display: flex;
  align-items: center;
  font-size: 12px;
}
.market-game-team-name {
  flex: auto;
  color: rgba(var(--b-sc-rgb), 0.9);
}
.market-game-odd {
  flex-shrink: 0;
  -webkit-margin-start: 4px;
  margin-inline-start: 4px;
  color: var(--accent);
}
.market-game-part-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 22px;
  color: rgba(var(--b-sc-rgb), 0.4);
}
.market-game-part-container > i {
  font-size: 10px;
}
.market-game-part-container > b {
  height: 15px;
  min-width: 23px;
  padding: 0 2px;
  color: rgba(var(--b-sc-rgb), 0.4);
  background: rgba(var(--b-rgb), 0.8);
  border-radius: 4px;
  text-align: center;
  font-size: 11px;
  line-height: 15px;
  font-weight: 400;
}
.market-game-part-container > b,
.market-game-part-container > i {
  -webkit-margin-start: 4px;
  margin-inline-start: 4px;
}
.market-game-part-container .bc-loader.small .bc-loader-contain {
  max-width: 10px;
  height: 10px;
  line-height: 10px;
}
.market-game-part-container .bc-loader.small .vivaro-loader {
  width: 10px;
}
.market-game-part {
  flex: auto;
  font-size: 11px;
  line-height: 22px;
}
.market-game-additional-info-container {
  display: flex;
  align-items: center;
  color: rgba(var(--b-sc-rgb), 0.4);
  font-size: 11px;
}
.market-game-additional-info {
  flex: auto;
}
.market-game-additional-info-time {
  flex-shrink: 0;
  -webkit-margin-start: 4px;
  margin-inline-start: 4px;
  font-weight: 400;
}
.sportsbook {
  grid-template-columns:  1fr 330px !important;
  grid-template-areas: "header header" "content betslip" "footer footer" !important;
}
.sportsbook .sgm-body-bc {
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  -webkit-padding-end: 0;
  padding-inline-end: 0;
}
.sportsbook .sgm-market-search {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  box-shadow: 0 0 0 2px var(--b);
  background: var(--b);
  z-index: 1;
}
.sportsbook .horizontal-sl-tab {
  box-shadow: none;
}
.sportsbook .sgm-market-g-item-bc > .market-bc {
  min-height: 34px;
}
.sportsbook .market-group-holder-bc .market-bc {
  display: flex;
  flex-direction: row;
  min-height: 34px;
  padding: 4px 10px;
}
.sportsbook .market-group-holder-bc .market-coefficient-bc,
.sportsbook .market-group-holder-bc .market-name-bc {
  flex-shrink: 0;
}
.sportsbook .market-group-holder-bc .market-odd-bc {
  font-size: 12px;
  line-height: 14px;
}
.sportsbook .sgm-market-g-head-bc {
  border-top-width: 2px;
}
.sportsbook .comp-name-fav-row-bc {
  border-radius: 4px;
}
.sportsbook .comp-name-fav-row-bc .flag-bc {
  -webkit-margin-end: 10px;
  margin-inline-end: 10px;
}
.sportsbook .casino-full-game-bg {
  position: fixed;
  width: calc(100% - 20px);
}
.sportsbook .casino-full-game-bg .casino-full-game-container {
  flex: unset;
}
.market-group-holder-bc.left-menu-market {
  margin-top: 10px;
}
.market-group-holder-bc .market-bc {
  display: flex;
  flex-direction: row;
  min-height: 34px;
  padding: 4px 10px;
}
.market-group-holder-bc .market-name-bc {
  flex: auto;
  text-align: start;
  margin-bottom: 0;
  -webkit-margin-end: 4px;
  margin-inline-end: 4px;
}
.market-group-holder-bc .market-coefficient-bc {
  flex-shrink: 0;
}
.market-group-holder-bc .market-odd-bc {
  font-size: 12px;
  line-height: 14px;
}
.sportsbook-container {
  display: flex;
  height: 100%;
  position: relative;
  padding-bottom: 10px;
}
.sportsbook-container.sportsbook-small-view .sgm-market-search {
  top: 38px;
}
.sportsbook-container.sportsbook-small-view.hide-competition-details
  .prematch-page-bc,
.sportsbook-container.sportsbook-small-view.hide-competition-details
  .sportsbook-center-section
  .special-games-container {
  display: none;
}
.sportsbook-container.sportsbook-small-view.hide-competition-details
  .game-details-section {
  width: 100%;
  -webkit-padding-start: 0;
  padding-inline-start: 0;
}
.left-menu-container {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 300px;
  background: var(--b);
  transition: width 0.24s;
}
.left-menu-container > div + div {
  margin-top: 2px;
}
.left-menu-container .sport-search-bc {
  position: relative;
  -webkit-padding-end: 37px;
  padding-inline-end: 37px;
}
[dir="ltr"] .left-menu-container .ss-icon-holder-bc {
  right: 0;
}
[dir="rtl"] .left-menu-container .ss-icon-holder-bc {
  left: 0;
}
.left-menu-container .second-tabs-bc .tab-bc.active,
.left-menu-container .second-tabs-bc .tab-bc:hover {
  color: var(--hero-sc);
  background: var(--hero);
}
.left-menu-container .filter-sports-bc {
  flex-shrink: 0;
  height: 46px;
  margin-bottom: 0;
  padding: 10px;
  background: rgba(var(--b-sc-rgb), 0.05);
  border-radius: 4px;
}
.left-menu-container .tournament-select {
  padding: 0;
}
.left-menu-container .tournament-select .multi-select-label-bc {
  width: 100%;
}
.left-menu-container .tournament-select .form-control-icon-bc {
  font-size: 12px;
}
.left-menu-container .special-games-container {
  padding: 0 5px;
}
[dir="ltr"] .left-menu-container .market-group-item-bc:first-child .market-bc {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
[dir="ltr"] .left-menu-container .market-group-item-bc:last-child .market-bc,
[dir="rtl"] .left-menu-container .market-group-item-bc:first-child .market-bc {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
[dir="rtl"] .left-menu-container .market-group-item-bc:last-child .market-bc {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.left-menu-search {
  display: flex;
  align-items: center;
  padding: 10px;
  background: rgba(var(--b-sc-rgb), 0.05);
  border-radius: 4px;
  position: relative;
}
.left-menu-close-icon {
  -webkit-margin-start: 13px;
  margin-inline-start: 13px;
  color: rgba(var(--b-sc-rgb), 0.8);
  font-size: 18px;
  cursor: pointer;
}
.left-menu-close-icon:hover {
  color: var(--b-sc);
}
.pp-sport-list-holder-bc {
  flex: auto;
}
.sportsbook-center-section {
  flex: auto;
  height: 100%;
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
  position: relative;
}
.sportsbook-center-section .express-of-the-day,
.sportsbook-center-section .game-details-section,
.sportsbook-center-section .prematch-page-bc,
.sportsbook-center-section .special-games-container,
.sportsbook-center-section .special-games-container > .popular-matches-bc {
  flex-shrink: 0;
  height: 100%;
  -webkit-padding-end: 2px;
  padding-inline-end: 2px;
  will-change: scroll-position;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.sportsbook-center-section .express-of-the-day:only-child,
.sportsbook-center-section .game-details-section:only-child,
.sportsbook-center-section .prematch-page-bc:only-child,
.sportsbook-center-section .special-games-container:only-child,
.sportsbook-center-section
  .special-games-container
  > .popular-matches-bc:only-child {
  width: 100%;
}
.sportsbook-center-section .express-of-the-day:not(:only-child),
.sportsbook-center-section .game-details-section:not(:only-child),
.sportsbook-center-section .prematch-page-bc:not(:only-child),
.sportsbook-center-section .special-games-container:not(:only-child),
.sportsbook-center-section
  .special-games-container
  > .popular-matches-bc:not(:only-child) {
  width: 50%;
}
.sportsbook-center-section .prematch-page-bc + .game-details-section,
.sportsbook-center-section .special-games-container + .game-details-section {
  -webkit-padding-start: 10px;
  padding-inline-start: 10px;
}
.sportsbook-center-section .market-filter-cell-bc {
  width: 165px;
}
.sportsbook-center-section .c-info-content-bc {
  width: 78px;
  padding: 0 5px;
}
.sportsbook-center-section .c-info-time-bc {
  -webkit-margin-start: 5px;
  margin-inline-start: 5px;
}
.sportsbook-center-section .c-teams-bc {
  -webkit-padding-start: 5px;
  padding-inline-start: 5px;
}
.sportsbook-center-section .c-info-more-bc {
  padding: 0 5px;
}
.sportsbook-center-section .c-odd-bc {
  width: 54px;
}
.sportsbook-center-section .c-odd-bc:nth-child(4):nth-last-child(2),
.sportsbook-center-section .c-odd-bc:nth-child(5):last-child {
  width: 81px;
}
.sportsbook-center-section .express-of-the-day .competition-wrapper-bc .btn {
  max-width: 100%;
}
.sportsbook-center-section-scroll {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.sportsbook-center-section-scroll .special-games-container {
  margin: 0;
}
.sportsbook-center-section-scroll .special-games-container:only-child {
  width: 100%;
}
.sportsbook-center-section-scroll .special-games-container:not(:only-child) {
  width: 50%;
}
.game-details-section .live-page-sections-bc:first-child {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: var(--b);
  z-index: 1;
}
.prematch-page-bc .market-filter-bc {
  margin-bottom: 0;
}
.comp-live-title-bc {
  cursor: pointer;
  transition: background 0.24s;
}
.comp-live-title-bc:hover {
  background: var(--oc-1-hover);
}
.betslip-game-animation-block .sport-game-detail-holder-bc {
  padding: 0;
}
.special-games-container {
  margin: 10px 0;
}
.comp-name-fav-row-bc {
  display: flex;
  align-items: center;
  width: 100%;
  height: 36px;
  margin-bottom: 1px;
  position: relative;
  padding: 0 10px;
  background: rgba(var(--b-sc-rgb), 0.1);
}
.comp-title-w-bc {
  flex: auto;
  font-size: 13px;
  color: rgba(var(--b-sc-rgb), 0.9);
}
.comp-live-title-bc {
  flex-shrink: 0;
  height: 22px;
  padding: 0 10px;
  -webkit-margin-start: 6px;
  margin-inline-start: 6px;
  -webkit-margin-end: 11px;
  margin-inline-end: 11px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  color: var(--oc-1-sc);
  background: var(--oc-1);
  font-size: 11px;
  line-height: 24px;
  font-weight: 500;
}
.comp-live-title-bc > b {
  font-weight: inherit;
}
.comp-fav-icon-bc {
  flex-shrink: 0;
  font-size: 17px;
  -webkit-margin-start: 6px;
  margin-inline-start: 6px;
  color: rgba(var(--b-sc-rgb), 0.8);
  cursor: pointer;
}
.today-bets-container {
  justify-content: flex-end;
}
.back-nav-bc {
  display: flex;
  align-items: center;
  width: 100%;
  height: 38px;
  padding: 0 7px;
  position: relative;
  cursor: pointer;
}
.back-nav-bc,
.back-nav-bc > i {
  flex-shrink: 0;
}
.back-nav-bc > i + i {
  -webkit-margin-start: 8px;
  margin-inline-start: 8px;
}
.back-nav-icon-bc {
  color: rgba(var(--b-sc-rgb), 0.8);
  font-size: 20px;
}
[dir="rtl"] .back-nav-icon-bc:before {
  content: "оҐ—";
}
.back-nav-title-bc {
  flex: auto;
  margin: 0 8px;
  color: var(--b-sc);
  text-transform: uppercase;
  font-size: 14px;
}
.casino-game-details .back-nav-bc,
.casinoTournamentPageWrapper-bc .back-nav-bc,
.skill-game .back-nav-bc {
  top: 0;
  z-index: 10;
  position: -webkit-sticky;
  position: sticky;
  background: rgba(0, 0, 0, 0.4);
}
.back-nav-favorite-icon,
.casino-game-details .back-nav-icon-bc,
.casino-game-details .back-nav-title-bc,
.casinoTournamentPageWrapper-bc .back-nav-icon-bc,
.casinoTournamentPageWrapper-bc .back-nav-title-bc,
.skill-game .back-nav-icon-bc,
.skill-game .back-nav-title-bc {
  color: hsla(0, 0%, 100%, 0.8);
}
.back-nav-favorite-icon {
  width: 22px;
  height: 22px;
  text-align: center;
  font-size: 17px;
  line-height: 22px;
}
.sgm-markets-head-bc {
  display: flex;
  align-items: center;
  height: 32px;
  cursor: pointer;
}
.sgm-markets-head-bc.hidden .sgm-m-h-toggle-icon-bc {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
.sgm-m-h-title-bc {
  flex: auto;
  font-size: 12px;
  font-weight: 500;
  color: var(--b-sc);
}
.sgm-m-h-toggle-icon-bc {
  flex-shrink: 0;
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
  color: rgba(var(--b-sc-rgb), 0.8);
  font-size: 11px;
}
.sgm-market-g-new-label {
  position: absolute;
  top: 4px;
  width: 5px;
  height: 5px;
  background: var(--hero);
  border-radius: 50%;
}
[dir="ltr"] .sgm-market-g-new-label {
  right: 6px;
}
[dir="rtl"] .sgm-market-g-new-label {
  left: 6px;
}
.sgm-body-bc {
  padding: 1px 2px;
}
.sgm-market-g {
  position: relative;
}
.sgm-market-g.hidden,
.sgm-market-g.hidden .large-market-button-bc,
.sgm-market-g.hidden .sgm-market-g-item-bc {
  display: none;
}
.sgm-market-g-h-toggle-icon-bc {
  color: rgba(var(--b-sc-rgb), 0.8);
  font-size: 11px;
}
.sgm-market-search {
  position: relative;
}
.sgm-market-search .sport-search-bc {
  z-index: 3;
  -webkit-padding-end: 0;
  padding-inline-end: 0;
}
[dir="ltr"] .sgm-market-search .sport-search-bc {
  left: 0;
}
[dir="rtl"] .sgm-market-search .sport-search-bc {
  right: 0;
}
.sgm-market-search .sport-search-bc.active {
  -webkit-padding-start: 37px;
  padding-inline-start: 37px;
}
.sgm-market-search .sport-search-bc .ss-icon-holder-bc {
  background: rgba(var(--b-sc-rgb), 0.2);
  border-radius: 0;
}
[dir="ltr"] .sgm-market-search .sport-search-bc .ss-icon-holder-bc {
  left: 0;
}
[dir="rtl"] .sgm-market-search .sport-search-bc .ss-icon-holder-bc {
  right: 0;
}
.sgm-market-search .sport-search-bc .ss-input-bc {
  -webkit-border-end: 1px solid rgba(var(--b-sc-rgb), 0.1);
  border-inline-end: 1px solid rgba(var(--b-sc-rgb), 0.1);
}
[dir="ltr"] .sgm-market-search .sport-search-bc .ss-input-bc {
  border-radius: 0 4px 4px 0;
}
[dir="rtl"] .sgm-market-search .sport-search-bc .ss-input-bc {
  border-radius: 4px 0 0 4px;
}
.sgm-market-search .horizontal-sl-tab {
  -webkit-margin-start: 37px;
  margin-inline-start: 37px;
}
.sgm-market-g-h-ew-title-bc {
  -webkit-margin-end: 7px;
  margin-inline-end: 7px;
}
.sgm-market-g-head-bc {
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  color: rgba(var(--b-sc-rgb), 0.8);
  background: rgba(var(--b-sc-rgb), 0.05);
  font-size: 12px;
  cursor: pointer;
  border-top: 3px solid transparent;
  background-clip: padding-box;
  box-sizing: initial;
}
.sgm-market-g-head-bc:nth-child(2) {
  margin-top: 0;
}
.sgm-market-g-head-bc.hidden .sgm-market-g-h-toggle-icon-bc {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
.sgm-market-g-head-bc > i {
  display: flex;
  flex-shrink: 0;
  line-height: 14px;
}
.sgm-market-g-head-bc > i + i {
  -webkit-margin-start: 7px;
  margin-inline-start: 7px;
  -webkit-padding-start: 8px;
  padding-inline-start: 8px;
  position: relative;
}
.sgm-market-g-head-bc > i + i:after {
  content: "";
  position: absolute;
  top: 0;
  width: 1px;
  height: 14px;
  background: var(--b);
}
[dir="ltr"] .sgm-market-g-head-bc > i + i:after {
  left: 0;
}
[dir="rtl"] .sgm-market-g-head-bc > i + i:after {
  right: 0;
}
.sgm-market-g-h-fav-icon-bc {
  flex-shrink: 0;
  font-size: 14px;
}
.sgm-market-g-h-title-bc {
  flex: auto;
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
  -webkit-margin-end: 5px;
  margin-inline-end: 5px;
}
.sgm-market-g-h-title {
  flex: 0 1 auto;
}
.sgm-market-g-h-title-label {
  flex: auto;
  -webkit-margin-end: 5px;
  margin-inline-end: 5px;
}
.sgm-market-g-h-title-label > span {
  padding: 1px 4px;
  color: var(--hero-sc);
  background: var(--hero);
  border-radius: 4px;
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 8px;
  font-weight: 500;
  letter-spacing: 1px;
}
.sgm-market-g-h-link-icon-bc:after {
  content: attr(data-count);
  -webkit-margin-start: 4px;
  margin-inline-start: 4px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}
.sgm-market-g-h-cashed-out-icon-bc {
  color: var(--oc-1);
  font-size: 14px;
}
.sgm-market-g-h-statistics-icon-bc {
  font-size: 14px;
}
.sgm-market-g-h-statistics-icon-bc.active {
  color: var(--oc-2);
}
.sgm-market-g-item-bc {
  width: 100%;
  display: grid;
  grid-gap: 1px;
  padding-top: 1px;
  grid-template-rows: auto;
}
.sgm-market-g-item-bc.column-1 {
  grid-template-columns: repeat(1, 100%);
}
.sgm-market-g-item-bc.column-0,
.sgm-market-g-item-bc.column-2,
.sgm-market-g-item-bc.default {
  grid-template-columns: repeat(auto-fit, minmax(49%, 1fr));
}
.sgm-market-g-item-bc.column-0
  > .sgm-market-g-i-cell-bc:last-child:not(:nth-child(2n)):nth-last-child(
    odd
  ):not(:first-child),
.sgm-market-g-item-bc.column-2
  > .sgm-market-g-i-cell-bc:last-child:not(:nth-child(2n)):nth-last-child(
    odd
  ):not(:first-child),
.sgm-market-g-item-bc.default
  > .sgm-market-g-i-cell-bc:last-child:not(:nth-child(2n)):nth-last-child(
    odd
  ):not(:first-child) {
  width: 200.5%;
}
.sgm-market-g-item-bc.column-3 {
  grid-template-columns: repeat(auto-fit, minmax(32%, 1fr));
}
.sgm-market-g-item-bc.column-3
  > .sgm-market-g-i-cell-bc:nth-child(3n + 1):last-child:not(:first-child) {
  width: 301%;
}
.sgm-market-g-item-bc.column-3
  > .sgm-market-g-i-cell-bc:nth-child(3n + 2):last-child:not(:nth-child(2)) {
  grid-column: 2/4;
}
.sgm-market-g-item-bc.collapsed {
  display: none;
}
.sgm-market-g-item-bc > .market-bc {
  flex-direction: row;
  min-height: 40px;
}
.sgm-market-g-item-bc > .market-bc .market-name-bc {
  flex: auto;
  text-align: start;
  margin-bottom: 0;
  -webkit-margin-end: 4px;
  margin-inline-end: 4px;
}
.sgm-market-g-item-bc > .market-bc .market-icon-bc {
  -webkit-margin-end: 4px;
  margin-inline-end: 4px;
}
.sgm-market-g-item-bc > .market-bc .market-coefficient-bc {
  flex-shrink: 0;
}
.sgm-market-g-item-bc.show-less {
  max-height: 287px;
  overflow: hidden;
}
.large-market-button-bc {
  margin-bottom: 6px;
}
.sport-game-detail-holder-bc {
  margin-bottom: 10px;
}
.sgm-market-g .main-tabs-bc {
  height: 34px;
  margin-top: 2px;
}
.sgm-market-g .tab-bc {
  margin-bottom: 2px;
  font-size: 12px;
}
.sgm-market-g .tab-bc:only-child {
  margin-bottom: 0;
  position: relative;
}
.sgm-market-g .tab-bc:only-child.active {
  box-shadow: none;
}
.sgm-market-g .tab-bc:only-child:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 5px;
  width: calc(100% - 10px);
  height: 1px;
  background: var(--b);
}
.game-details-container-inner-bc {
  padding: 10px;
  position: relative;
  border-radius: 4px;
}
.game-details-container-inner-bc:not(.live-score-bg-pistolshooting):before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.4);
}
.game-details-container-inner-bc > div {
  position: relative;
}
.live-score-bg-compoundarchery .shooting-total-score + .shooting-total-score {
  display: none;
}
.game-details-c-head-bc {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
  position: relative;
}
.game-details-c-head-bc .competition-icon-arrow-bc {
  line-height: 18px;
}
.game-details-c-head-bc .notification-loader {
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
}
.game-details-c-head-bc .bc-loader.small .bc-loader-contain {
  max-width: 12px;
  height: 12px;
  line-height: 12px;
}
.game-details-c-head-bc .bc-loader.small .vivaro-loader {
  width: 12px;
}
.game-details-c-head-title-bc {
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  align-items: center;
}
.game-details-c-head-title-bc * {
  margin-bottom: 4px;
}
.game-details-c-head-c-row-bc {
  flex: 100% 1;
  -webkit-margin-before: 5px;
  margin-block-start: 5px;
}
.game-d-c-h-c-r-sport-icon {
  flex-shrink: 0;
  font-size: 13px;
  color: hsla(0, 0%, 100%, 0.7);
}
.game-details-number {
  font-size: 12px;
  font-weight: 700;
  color: hsla(0, 0%, 100%, 0.9);
  -webkit-margin-start: 8px;
  margin-inline-start: 8px;
}
.game-d-c-h-c-r-sport-country {
  flex-shrink: 0;
  margin: 0 8px 4px;
}
.game-d-c-h-c-r-sport-league-bc {
  flex: auto;
  font-size: 12px;
  line-height: 14px;
  text-transform: capitalize;
  color: #ccc;
}
.game-details-c-head-time-bc {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-weight: 500;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
}
.game-details-c-head-time-bc:not(.stoppage-time) {
  padding: 0 5px;
  color: var(--oc-1-sc);
}
.game-details-c-head-time-bc:nth-child(2) {
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
  background: rgba(var(--oc-1-rgb), 0.5);
}
.game-details-c-head-time-bc:nth-child(2):last-child {
  background: var(--oc-1);
  border-radius: 4px;
}
[dir="ltr"] .game-details-c-head-time-bc:nth-child(2):nth-last-child(2) {
  border-radius: 4px 0 0 4px;
}
[dir="rtl"] .game-details-c-head-time-bc:nth-child(2):nth-last-child(2) {
  border-radius: 0 4px 4px 0;
}
.game-details-c-head-time-bc
  + .game-details-c-head-time-bc:not(.stoppage-time) {
  -webkit-margin-start: 1px;
  margin-inline-start: 1px;
  background: var(--oc-1);
}
[dir="ltr"]
  .game-details-c-head-time-bc
  + .game-details-c-head-time-bc:not(.stoppage-time) {
  border-radius: 0 4px 4px 0;
}
[dir="rtl"]
  .game-details-c-head-time-bc
  + .game-details-c-head-time-bc:not(.stoppage-time) {
  border-radius: 4px 0 0 4px;
}
.game-details-c-head-time-bc + .stoppage-time {
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
}
.stoppage-time {
  position: relative;
  justify-content: center;
  color: var(--oc-1);
}
.stoppage-time:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid var(--oc-1);
  border-radius: 4px;
  -webkit-animation: stoppageTime 2.5s ease;
  animation: stoppageTime 2.5s ease;
}
@-webkit-keyframes stoppageTime {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
    -webkit-transform: scale(1.2) rotate(15deg);
    transform: scale(1.2) rotate(15deg);
    box-shadow: 0 0 10px 1px var(--oc-1);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: none;
  }
  75% {
    -webkit-transform: scale(1.2) rotate(-15deg);
    transform: scale(1.2) rotate(-15deg);
    box-shadow: 0 0 10px 1px var(--oc-1);
  }
}
@keyframes stoppageTime {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
    -webkit-transform: scale(1.2) rotate(15deg);
    transform: scale(1.2) rotate(15deg);
    box-shadow: 0 0 10px 1px var(--oc-1);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: none;
  }
  75% {
    -webkit-transform: scale(1.2) rotate(-15deg);
    transform: scale(1.2) rotate(-15deg);
    box-shadow: 0 0 10px 1px var(--oc-1);
  }
}
.live-page-sections-bc .competition-icon-arrow-bc {
  width: 24px;
  height: 24px;
  text-align: center;
  background: rgba(var(--b-sc-rgb), 0.1);
  border-radius: 4px;
  line-height: 24px;
}
.game-details-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: calc(
    100vh - 38px - var(--mobile-header-navigation-height) -
      var(--mobile-header-main-section-height) * 2 - var(--dynamic-header)
  );
  padding: 15px;
  background: rgba(var(--b-rgb), 0.7);
  z-index: 5;
  pointer-events: none;
}
.game-details-list .sp-sub-list-bc {
  background: rgba(var(--b-sc-rgb), 0.2);
  border-radius: 4px;
}
.game-details-list .sp-s-l-head-bc {
  height: 30px;
}
.game-details-list .c-row-bc {
  height: 40px;
}
.game-details-list-scroll {
  max-height: 80%;
  padding: 7px;
  background: var(--b);
  border-radius: 4px;
  pointer-events: auto;
  will-change: scroll-position;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: contain;
}
.game-details-additional-info-bc {
  display: flex;
  align-items: flex-start;
  margin-top: 8px;
}
.game-details-additional-info-bc .notification-loader {
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
}
.game-details-additional-info-bc .bc-loader.small .bc-loader-contain {
  max-width: 12px;
  height: 12px;
  line-height: 12px;
}
.game-details-additional-info-bc .bc-loader.small .vivaro-loader {
  width: 12px;
}
.game-details-additional-info-title-bc {
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  justify-content: center;
}
.game-details-c-head-c-row-data-bc {
  font-size: 12px;
  line-height: 16px;
  color: #ccc;
}
[dir="rtl"] .game-details-c-head-c-row-data-bc {
  direction: ltr;
}
.game-details-c-head-c-row-data-bc + .game-details-c-head-c-row-data-bc {
  -webkit-margin-start: 4px;
  margin-inline-start: 4px;
}
.game-details-c-head-column-bc {
  display: flex;
}
.game-d-c-head-c-icon-bc {
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
  color: #c5c5d8;
  cursor: pointer;
  font-size: 12px;
  line-height: 12px;
}
.game-details-c-body-bc {
  display: flex;
  width: 100%;
  overflow: hidden;
  padding: 10px 0;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: -11px 0 0 0 rgba(0, 0, 0, 0.3), 11px 0 0 0 rgba(0, 0, 0, 0.3);
}
.game-details-c-body-bc.set-score-bc {
  align-items: flex-end;
}
.game-details-c-body-name-bc {
  flex: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.game-details-c-body-count-bc {
  flex-shrink: 0;
  display: flex;
  max-width: 50%;
}
.game-details-c-body-count-bc > .game-details-c-body-numbers-bc {
  -webkit-padding-start: 10px;
  padding-inline-start: 10px;
}
[dir="ltr"]
  .game-details-c-body-count-bc
  > .game-details-c-body-numbers-bc:nth-child(2) {
  box-shadow: inset 1px 0 0 0 #a9a9c1, -10px 0 10px 0 hsla(0, 0%, 100%, 0.2);
}
[dir="rtl"]
  .game-details-c-body-count-bc
  > .game-details-c-body-numbers-bc:nth-child(2) {
  box-shadow: inset -1px 0 0 0 #a9a9c1, 10px 0 10px 0 hsla(0, 0%, 100%, 0.2);
}
.game-details-c-body-count-bc
  > .game-details-c-body-numbers-bc:nth-child(2)
  .game-details-c-b-r-c-score-item-bc.point {
  color: var(--oc-2);
}
.game-details-c-body-amount-bc {
  display: flex;
  will-change: scroll-position;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}
.game-details-c-body-amount-bc::-webkit-scrollbar {
  display: none;
}
.game-details-c-body-amount-bc
  .game-details-c-body-numbers-bc
  + .game-details-c-body-numbers-bc {
  -webkit-margin-start: 12px;
  margin-inline-start: 12px;
}
.game-details-c-body-amount-bc .game-details-c-body-numbers-bc:last-child {
  -webkit-padding-end: 10px;
  padding-inline-end: 10px;
}
.game-details-c-body-numbers-bc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.game-d-c-b-r-c-team-bc {
  display: flex;
  align-items: center;
  -webkit-margin-end: 10px;
  margin-inline-end: 10px;
}
.game-d-c-b-r-c-team-bc + .game-d-c-b-r-c-team-bc {
  margin-top: 3px;
}
.game-details-c-team-name-bc {
  display: flex;
  align-items: center;
}
.game-details-c-team-name-bc + .game-details-c-team-name-bc {
  margin-top: 5px;
}
.game-d-c-b-r-c-team-name-bc {
  flex: auto;
  font-size: 15px;
  text-transform: capitalize;
  color: #f2f2f2;
  -webkit-user-select: text;
  user-select: text;
  word-break: break-word;
}
.game-d-c-position-point-bc {
  font-size: 10px;
  line-height: 11px;
  color: #999;
  margin-bottom: 10px;
}
.game-d-c-position-point-bc:last-child {
  margin-bottom: 0;
}
.game-details-c-b-r-c-score-item-bc {
  text-align: center;
}
.game-details-c-b-r-c-score-item-bc.point {
  color: #fff;
  font-size: 16px;
}
.game-details-c-b-r-c-score-item-bc.title {
  line-height: 11px;
  font-size: 10px;
  text-transform: capitalize;
  color: #ccc;
}
.game-details-c-b-r-c-score-item-bc + .game-details-c-b-r-c-score-item-bc {
  margin-top: 3px;
}
.game-d-c-b-r-c-statistics-item-bc {
  display: flex;
  justify-content: center;
  line-height: 22px;
  width: 20px;
  height: 20px;
  font-size: 11px;
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.5);
}
.game-d-c-b-r-c-statistics-item-bc.W {
  color: #16c72e;
}
.game-d-c-b-r-c-statistics-item-bc.L {
  color: #ff3449;
}
.game-d-c-b-r-c-statistics-item-bc.D {
  color: gold;
}
[dir="ltr"] .game-d-c-b-r-c-statistics-item-bc:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
[dir="ltr"] .game-d-c-b-r-c-statistics-item-bc:last-child,
[dir="rtl"] .game-d-c-b-r-c-statistics-item-bc:first-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
[dir="rtl"] .game-d-c-b-r-c-statistics-item-bc:last-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.game-details-c-live-icons-row-bc {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #a9a9c1;
}
.game-details-c-live-i-item-bc {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 32px;
  max-width: 63px;
  color: #a6a6a6;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  transition: color 0.24s, background 0.24s;
}
.game-details-c-live-i-item-bc.active {
  color: #fff;
}
.game-details-c-live-i-item-bc + .game-details-c-live-i-item-bc {
  -webkit-margin-start: 5px;
  margin-inline-start: 5px;
}
.game-details-container-prematch-bc .game-details-c-body-bc {
  align-items: stretch;
}
.game-details-container-prematch-bc .game-details-c-body-numbers-bc {
  justify-content: space-around;
}
.game-details-container-prematch-bc .game-details-c-b-r-c-score-item-bc {
  display: flex;
}
.game-details-container-prematch-bc
  .game-details-c-b-r-c-score-item-bc:only-child.team1 {
  margin-bottom: 16px;
}
.game-details-container-prematch-bc
  .game-details-c-b-r-c-score-item-bc:only-child.team2 {
  margin-top: 16px;
}
.game-d-c-b-r-c-statistics-item-bc + .game-d-c-b-r-c-statistics-item-bc {
  -webkit-margin-start: 1px;
  margin-inline-start: 1px;
}
.game-details-penalties-bc {
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
}
.game-details-penalties-title-bc {
  margin-bottom: 10px;
  color: hsla(0, 0%, 100%, 0.6);
  font-size: 12px;
}
.game-details-penalties-info-bc {
  display: flex;
  align-items: center;
}
.game-details-penalties-info-bc
  .game-d-c-b-r-c-statistics-item-bc:nth-child(5n + 6) {
  -webkit-margin-start: 0;
  margin-inline-start: 0;
}
[dir="ltr"]
  .game-details-penalties-info-bc
  .game-d-c-b-r-c-statistics-item-bc:nth-child(5n + 6) {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
[dir="rtl"]
  .game-details-penalties-info-bc
  .game-d-c-b-r-c-statistics-item-bc:nth-child(5n + 6) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.game-details-penalties-info-bc
  .game-d-c-b-r-c-statistics-item-bc:nth-child(n + 6) {
  margin-top: 1px;
}
[dir="ltr"]
  .game-details-penalties-info-bc
  .game-d-c-b-r-c-statistics-item-bc:nth-child(5n) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
[dir="rtl"]
  .game-details-penalties-info-bc
  .game-d-c-b-r-c-statistics-item-bc:nth-child(5n) {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.game-details-penalties-info-bc .bc-i-checked {
  color: #16c72e;
}
.game-details-penalties-info-bc .bc-i-close-remove {
  color: #ff3449;
  font-size: 9px;
}
.game-details-penalties-games-bc {
  display: flex;
  width: 104px;
  flex-wrap: wrap;
}
.game-details-penalties-score-bc {
  display: flex;
  margin: 0 12px;
}
.game-details-penalties-number-bc {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
}
.game-details-penalties-number-bc + .game-details-penalties-number-bc {
  -webkit-margin-start: 1px;
  margin-inline-start: 1px;
}
.game-details-penalties-number-bc:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.game-details-penalties-number-bc:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
@media screen and (max-width: 354px) {
  .game-details-penalties-score-bc {
    margin: 0 6px;
  }
  .game-details-penalties-number-bc {
    width: 28px;
    height: 28px;
  }
}
.mixed-matches-container {
  padding: 0 5px 5px;
  background: rgba(var(--b-sc-rgb), 0.05);
}
.mixed-matches-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px 4px 0 0;
  background: rgba(var(--b-sc-rgb), 0.1);
}
.mixed-matches-title {
  width: 100%;
  color: var(--b-sc);
  font-size: 12px;
  padding: 0 18px;
}
.mixed-matches-title:first-child {
  text-align: end;
}
.mixed-matches-team {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  margin-top: 1px;
  padding: 0 5px;
  color: rgba(var(--b-sc-rgb), 0.5);
  background: rgba(var(--b-sc-rgb), 0.05);
  font-size: 12px;
}
.mixed-matches-team:last-child {
  border-radius: 0 0 4px 4px;
}
.mixed-matches-player {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}
.mixed-matches-player > img {
  width: 18px;
  height: auto;
  margin: 0 5px;
}
.mixed-matches-player:first-child {
  justify-content: flex-end;
}
.mixed-matches-vs {
  margin: 0 5px;
}
.bet-builder-container {
  display: inline;
}
.sgm-market-search .bet-builder-container .bc-i-bet-builder {
  display: none;
}
.live-score-bg-custom {
  /* background: url(/static/media/custom.2e2b369a.png) no-repeat 50% 50% / cover; */
}
.live-score-bg-alpineskiing {
  /* background: url(/static/media/alpineskiing.fffa452f.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-americanfootball {
  /* background: url(/static/media/americanfootball.bcb75af4.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-archery,
.live-score-bg-archeryh2h,
.live-score-bg-compoundarchery {
  /* background: url(/static/media/archery.cffe675d.png) no-repeat 50% 50% / cover; */
}
.live-score-bg-australianfootball {
  /* background: url(/static/media/australianfootball.4b9c9432.png) no-repeat 50% */
  /* 50% / cover; */
}
.live-score-bg-badminton {
  /* background: url(/static/media/badminton.8f4c8835.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-baseball {
  background: url("../images/png/sportsBackground/basketball.png") no-repeat 50%
    50% / cover;
}

.live-score-bg-basketball {
  background: url("../images/png/sportsBackground/basketball.png") no-repeat 50%
    50% / cover;
}

.live-score-bg-basketballshots {
  /* background: url(/static/media/basketballshots.0515d678.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-beachfootball {
  /* background: url(/static/media/beachfootball.1ce086a3.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-beachvolleyball {
  /* background: url(/static/media/beachvolleyball.c84b1612.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-boxing {
  /* background: url(/static/media/boxing.94b35c38.png) no-repeat 50% 50% / cover; */
}
.live-score-bg-callofduty {
  /* background: url(/static/media/callofduty.24feac59.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-chess {
  /* background: url(/static/media/chess.b7e02c5f.png) no-repeat 50% 50% / cover; */
}
.live-score-bg-counterstrike {
  /* background: url(/static/media/counterstrike.a2c074c9.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-cricket {
  /* background: url(/static/media/cricket.93ed881b.png) no-repeat 50% 50% / cover; */
}
.live-score-bg-cyberfootball {
  /* background: url(/static/media/cyberfootball.42c58cbd.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-cycling {
  /* background: url(/static/media/cycling.3b3c8cd2.png) no-repeat 50% 50% / cover; */
}
.live-score-bg-darts {
  /* background: url(/static/media/darts.b115ae2d.png) no-repeat 50% 50% / cover; */
}
.live-score-bg-dota,
.live-score-bg-dota2 {
  /* background: url(/static/media/dota.09ac0a05.png) no-repeat 50% 50% / cover; */
}
.live-score-bg-ebasketball {
  background: url("../images/png/sportsBackground/basketball.png") no-repeat 50%
    50% cover;
}
.live-score-bg-esports {
  /* background: url(/static/media/esports.c1e00c2f.png) no-repeat 50% 50% / cover; */
}
.live-score-bg-eurovision {
  /* background: url(/static/media/eurovision.e96c1f34.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-formula1 {
  /* background: url(/static/media/formula1.6fe4a381.png) no-repeat 50% 50% / cover; */
}
.live-score-bg-freestylewrestling {
  /* background: url(/static/media/freestylewrestling.5c4944d0.png) no-repeat 50% */
  /* 50% / cover; */
}
.live-score-bg-futsal {
  /* background: url(/static/media/futsal.d6a870a3.png) no-repeat 50% 50% / cover; */
}
.live-score-bg-golf {
  /* background: url(/static/media/golf.9d07b080.png) no-repeat 50% 50% / cover; */
}
.live-score-bg-grasshockey {
  /* background: url(/static/media/grasshockey.56d23066.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-grecoromanwrestling {
  /* background: url(/static/media/grecoromanwrestling.f211263a.png) no-repeat 50% */
  /* 50% / cover; */
}
.live-score-bg-handball {
  background: url("../images/png/sportsBackground/handball.png") no-repeat 50%
    50% / cover;
}
.live-score-bg-hearthstone {
  /* background: url(/static/media/hearthstone.0891d984.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-horseracing {
  /* background: url(/static/media/horseracing.40573ee0.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-e-icehockey,
.live-score-bg-icehockey {
  /* background: url(/static/media/icehockey.e6a6aba9.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-judo {
  /* background: url(/static/media/judo.f964f5a8.png) no-repeat 50% 50% / cover; */
}
.live-score-bg-leagueoflegends {
  /* background: url(/static/media/leagueoflegends.3f83d982.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-mma {
  /* background: url(/static/media/mma.925beb18.png) no-repeat 50% 50% / cover; */
}
.live-score-bg-mortalkombatxl {
  /* background: url(/static/media/mortalkombatxl.0b7d7f1a.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-oscar {
  /* background: url(/static/media/oscar.3bb70aac.png) no-repeat 50% 50% / cover; */
}
.live-score-bg-overwatch {
  /* background: url(/static/media/overwatch.92ffcef7.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-pistolhead2head,
.live-score-bg-pistolshooting {
  /* background: url(/static/media/pistolshooting.a4e36202.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-rugby {
  /* background: url(/static/media/rugby.bb4258c6.png) no-repeat 50% 50% / cover; */
}
.live-score-bg-rugbyleague {
  /* background: url(/static/media/rugbyleague.496e0369.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-rugbysevens {
  /* background: url(/static/media/rugbysevens.8d0fc854.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-rugbyunion {
  /* background: url(/static/media/rugbyunion.705a510e.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-snooker {
  /* background: url(/static/media/snooker.82a5554c.png) no-repeat 50% 50% / cover; */
}
.live-score-bg-soccer {
  background: url("../images/png/sportsBackground/soccer.png") no-repeat 50% 50% /
    cover;
}
.live-score-bg-starcraft {
  /* background: url(/static/media/starcraft.81ad0119.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-starcraft2 {
  /* background: url(/static/media/starcraft2.71cc8af2.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-streetfighterv {
  /* background: url(/static/media/streetfighterv.b70d8c53.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-tabletennis {
  /* background: url(/static/media/tabletennis.196f7223.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-etennis,
.live-score-bg-tennis {
  /* background: url(/static/media/tennis.ca4ae9a3.png) no-repeat 50% 50% / cover; */
}
.live-score-bg-tennisgrass {
  /* background: url(/static/media/tennisgrass.b460e274.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-volleyball {
  /* background: url(/static/media/volleyball.b68a74c4.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-worldoftanks {
  /* background: url(/static/media/worldoftanks.84c10ac8.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-wrestling {
  /* background: url(/static/media/wrestling.7c798201.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-waterpolo {
  /* background: url(/static/media/waterpolo.1443f53e.png) no-repeat 50% 50% / */
  /* cover; */
}
.live-score-bg-3x3basketball {
  /* background: url(/static/media/3x3basketball.0b3d90e6.png) no-repeat 50% 50% / */
  /* cover; */
}
.sgm-body-bc .large-market-button-bc {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.sgm-market-search {
  display: flex;
  border-radius: 4px;
  overflow: hidden;
}
.sgm-market-g-head-bc {
  -webkit-padding-start: 10px;
  padding-inline-start: 10px;
  -webkit-padding-end: 10px;
  padding-inline-end: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.sgm-markets-head-bc {
  -webkit-padding-start: 10px;
  padding-inline-start: 10px;
  -webkit-padding-end: 15px;
  padding-inline-end: 15px;
  background: rgba(var(--b-sc-rgb), 0.1);
  border-radius: 4px;
}
.sgm-markets-head-bc.hidden {
  background: rgba(var(--b-sc-rgb), 0.15);
}
.sgm-market-g-item-bc > .market-bc {
  padding: 0 10px;
}
.horizontal-sl-tab {
  width: 100%;
}
.games-markets-columns-switcher {
  display: flex;
  align-items: center;
  padding: 0 10px;
  background: rgba(var(--b-sc-rgb), 0.1);
}
.games-markets-columns-switcher i {
  color: rgba(var(--b-sc-rgb), 0.5);
  font-size: 18px;
  cursor: pointer;
  transition: color 0.24s;
}
.games-markets-columns-switcher i.active,
.games-markets-columns-switcher i:hover {
  color: var(--b-sc);
}
.games-markets-columns-switcher i + i {
  -webkit-margin-start: 5px;
  margin-inline-start: 5px;
}
.markets-list-two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
  column-gap: 5px;
  grid-row-gap: 2px;
  row-gap: 2px;
}
.markets-list-two-columns > div {
  overflow: hidden;
}
.game-details-container-bc {
  margin-bottom: 2px;
}
.game-details-c-live-i-item-bc:hover {
  color: #fff;
}
.game-details-c-head-time-bc {
  height: 30px;
}
.game-details-c-head-time-bc:not(.stoppage-time) {
  font-size: 12px;
}
.stoppage-time {
  padding: 0 15px;
  font-size: 14px;
}
.live-page-sections-bc .main-tabs-bc {
  background: rgba(var(--b-sc-rgb), 0.1);
}
.live-page-sections-bc .sgm-market-g .tab-bc {
  margin-bottom: 0;
}
.live-page-sections-bc .sgm-market-g .tab-bc:not(:only-child) {
  border-bottom: 1px solid var(--b);
}
.sgm-market-statistics-block-bc {
  display: flex;
  padding: 10px 5px;
  background: rgba(var(--b-sc-rgb), 0.15);
}
.sgm-market-statistics-set-betting-bc {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  -webkit-padding-end: 5px;
  padding-inline-end: 5px;
  overflow: hidden;
}
.sgm-market-statistics-set-betting-bc + .sgm-market-statistics-set-betting-bc {
  -webkit-padding-start: 5px;
  padding-inline-start: 5px;
  position: relative;
}
.sgm-market-statistics-set-betting-bc
  + .sgm-market-statistics-set-betting-bc:before {
  content: "";
  width: 1px;
  position: absolute;
  top: 6px;
  height: calc(100% - 11px);
  background: var(--b);
}
[dir="ltr"]
  .sgm-market-statistics-set-betting-bc
  + .sgm-market-statistics-set-betting-bc:before {
  left: 0;
}
[dir="rtl"]
  .sgm-market-statistics-set-betting-bc
  + .sgm-market-statistics-set-betting-bc:before {
  right: 0;
}
.sgm-market-s-s-b-count-bc {
  font-size: 14px;
  font-weight: 500;
  color: rgba(var(--b-sc-rgb), 0.9);
}
.sgm-market-s-s-b-name-bc {
  max-width: 100%;
  font-size: 10px;
  color: rgba(var(--b-sc-rgb), 0.8);
}
.sgm-market-s-s-b-title-bc {
  max-width: 100%;
  font-size: 10px;
  color: rgba(var(--b-sc-rgb), 0.5);
}
.sgm-market-statistics-set-winner-block-bc {
  flex-direction: column;
}
.sgm-market-statistics-s-w-content-bc {
  display: flex;
  flex-direction: column;
}
.sgm-market-statistics-s-w-content-bc + .sgm-market-statistics-s-w-content-bc {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid var(--b);
}
.sgm-market-statistics-info-bc {
  display: flex;
}
.sgm-market-statistics-s-w-bc {
  display: flex;
  width: 100%;
  align-items: center;
  overflow: hidden;
}
.sgm-market-statistics-s-w-bc:first-child {
  justify-content: flex-end;
}
.sgm-market-statistics-s-w-bc:first-child .sgm-market-s-s-w-count-bc {
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
}
.sgm-market-statistics-s-w-bc:nth-child(2) {
  justify-content: flex-start;
}
.sgm-market-statistics-s-w-bc:nth-child(2) .sgm-market-s-s-w-count-bc {
  -webkit-margin-end: 10px;
  margin-inline-end: 10px;
}
.sgm-market-statistics-s-w-bc + .sgm-market-statistics-s-w-bc {
  -webkit-margin-start: 33px;
  margin-inline-start: 33px;
}
.sgm-market-s-s-w-name-bc {
  font-size: 10px;
  text-align: center;
  color: rgba(var(--b-sc-rgb), 0.8);
}
.sgm-market-s-s-w-count-bc {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  color: var(--b-sc);
  font-size: 11px;
  line-height: 22px;
  border-radius: 4px;
  text-align: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background: rgba(var(--b-sc-rgb), 0.1);
}
.sgm-market-s-s-w-count-bc + .sgm-market-s-s-w-count-bc {
  -webkit-margin-start: 3px;
  margin-inline-start: 3px;
}
[class*="sgm-market-s"].W,
[class*="sgm-market-s"].Y {
  color: #fff;
  background: #109121;
}
[class*="sgm-market-s"].D {
  color: #000;
  background: gold;
}
[class*="sgm-market-s"].L {
  color: #fff;
  background: #ff3449;
}
.sgm-market-statistics-date {
  margin-top: 4px;
}
.sgm-market-statistics-date,
.sgm-market-statistics-status-bc {
  font-size: 10px;
  color: rgba(var(--b-sc-rgb), 0.4);
  text-align: center;
}
.sgm-market-statistics-status-bc {
  font-weight: 400;
}
.sgm-market-s-previous-matches-bc {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}
.sgm-market-s-previous-matches-bc:first-child {
  -webkit-padding-end: 5px;
  padding-inline-end: 5px;
}
.sgm-market-s-previous-matches-bc:nth-child(2) {
  -webkit-padding-start: 5px;
  padding-inline-start: 5px;
}
.sgm-market-s-p-m-info-bc {
  align-items: center;
  margin: 10px 0 5px;
}
.sgm-market-s-h-f-t-block-bc,
.sgm-market-s-p-m-info-bc {
  display: flex;
  justify-content: center;
}
.sgm-market-s-h-f-t-content-bc {
  display: flex;
}
.sgm-market-s-h-f-t-content-bc + .sgm-market-s-h-f-t-content-bc {
  -webkit-margin-start: 5px;
  margin-inline-start: 5px;
}
.sgm-market-s-h-f-t-bc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 20px;
  height: 35px;
  padding: 5px 3px;
  color: #fff;
}
.sgm-market-s-h-f-t-bc + .sgm-market-s-h-f-t-bc {
  -webkit-margin-start: 1px;
  margin-inline-start: 1px;
}
.sgm-market-s-h-f-t-bc:first-child {
  border-radius: 3px 0 0 3px;
}
.sgm-market-s-h-f-t-bc:nth-child(2) {
  border-radius: 0 3px 3px 0;
}
.sgm-market-s-h-f-t-bc > span {
  font-size: 10px;
  line-height: 10px;
}
.sgm-market-statistics-all-bc {
  display: flex;
  align-items: center;
  height: 34px;
  margin-top: 1px;
  padding: 0 10px;
  color: rgba(var(--b-sc-rgb), 0.5);
  background: rgba(var(--b-sc-rgb), 0.1);
}
.sgm-market-statistics-all-text-bc {
  flex: auto;
  -webkit-margin-end: 10px;
  margin-inline-end: 10px;
  font-size: 12px;
}
.sgm-market-statistics-all-arrow-bc {
  font-size: 11px;
  flex-shrink: 0;
  width: 10px;
  height: 100%;
  color: rgba(var(--b-sc-rgb), 0.8);
  position: relative;
}
.sgm-market-statistics-all-arrow-bc:before {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 34px;
}
[dir="rtl"] .sgm-market-statistics-all-arrow-bc:before {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.sgm-market-input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.large-market-button-bc {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  padding: 0 10px;
  color: rgba(var(--b-sc-rgb), 0.8);
  background: rgba(var(--b-sc-rgb), 0.1);
  font-size: 12px;
  text-transform: capitalize;
  text-decoration: underline;
  cursor: pointer;
}
.head-to-head-section-bc {
  display: table;
  table-layout: fixed;
  width: 100%;
  border-spacing: 10px 6px;
  border-radius: 4px;
  background: rgba(var(--b-sc-rgb), 0.05);
}
.hth-row-bc {
  display: table-row;
}
.hth-cell-bc {
  display: table-cell;
  vertical-align: top;
}
.hth-title-bc {
  display: block;
  color: rgba(var(--b-sc-rgb), 0.7);
  text-align: center;
}
.hth-graph-info-container-bc {
  width: 100%;
  position: relative;
  height: 12px;
  margin-top: 4px;
  padding: 0 20px;
}
.hth-graph-holder {
  height: 4px;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}
.hth-graph-holder.no-changes {
  background: rgba(var(--b-sc-rgb), 0.5);
}
.hth-graph-holder.has-changes {
  background: rgba(var(--b-sc-rgb), 0.8);
}
.hth-graph-bc {
  display: inline-block;
  vertical-align: top;
  height: 100%;
  background-color: var(--oc-1);
  position: absolute;
  top: 0;
  transition: width 0.24s linear;
}
[dir="ltr"] .hth-graph-bc {
  left: 0;
}
[dir="rtl"] .hth-graph-bc {
  right: 0;
}
.hth-graph-score-bc {
  display: inline-block;
  vertical-align: top;
  height: 100%;
  width: 20px;
  text-align: center;
  font-weight: 700;
  position: absolute;
  top: 0;
  z-index: 2;
  color: rgba(var(--b-sc-rgb), 0.6);
}
[dir="ltr"] .hth-graph-score-bc.left {
  left: 0;
}
[dir="ltr"] .hth-graph-score-bc.right,
[dir="rtl"] .hth-graph-score-bc.left {
  right: 0;
}
[dir="rtl"] .hth-graph-score-bc.right {
  left: 0;
}
.hth-title-bc {
  font-size: 12px;
}
.hth-graph-score-bc {
  font-size: 10px;
}
.hth-graph-holder {
  top: 5px;
}
.stats-section-bc {
  display: flex;
  flex-direction: column;
  padding: 10px 4px 10px 10px;
  border-radius: 4px;
}
.stats-section-row-bc {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 12px;
}
.stats-section-row-bc + .stats-section-row-bc {
  margin-top: 6px;
}
.stats-section-row-bc:first-child {
  margin-bottom: 6px;
}
.stats-section-name-bc {
  display: flex;
  align-items: center;
  flex: auto;
  overflow: hidden;
}
.stats-section-logo-bc {
  max-width: 100%;
  height: 12px;
  flex-shrink: 0;
}
.stats-section-title-bc {
  padding: 0 6px;
  color: rgba(var(--b-sc-rgb), 0.7);
}
.stats-section-info-bc {
  width: 28px;
  flex-shrink: 0;
  text-align: center;
  color: rgba(var(--b-sc-rgb), 0.9);
}
.stats-section-icons-bc {
  width: 28px;
  font-size: 10px;
  text-align: center;
  color: var(--b-sc);
}
.stats-section-icons-bc.red {
  color: #ff3449;
}
.stats-section-icons-bc.yellow {
  color: gold;
}
.stats-section-bc {
  background: rgba(var(--b-sc-rgb), 0.1);
}
.stats-section-info-bc,
.stats-section-title-bc {
  font-size: 12px;
}
.scores-section-bc {
  display: table;
  min-width: 100%;
  padding: 4px;
  border-top: 1px solid #2b2b40;
  border-top: 1px solid var(--skin-palette-5);
  background-color: #212131;
  background-color: var(--skin-palette-4);
}
.scores-section-row-bc {
  display: table-row;
}
.scores-section-cell-bc {
  display: table-cell;
  vertical-align: top;
}
[dir="ltr"] .scores-section-cell-bc {
  border-right: 8px solid transparent;
}
[dir="rtl"] .scores-section-cell-bc {
  border-left: 8px solid transparent;
}
.scores-section-cell-bc:last-child {
  border: none;
}
.scores-section-cell-bc.ext-1 {
  width: 100%;
  position: relative;
}
.scores-section-cell-bc.ext-2 {
  text-align: center;
}
.scores-section-info-bc {
  display: block;
  height: 16px;
  font-weight: 400;
  font-size: 8px;
  text-transform: capitalize;
  line-height: 16px;
}
.scores-section-info-bc.title {
  color: #8c8c8c;
  color: var(--text-palette-12);
}
.scores-section-info-bc.team {
  padding: 0 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.scores-section-info-bc.score,
.scores-section-info-bc.team {
  color: rgba(var(--b-sc-rgb), 0.9);
}
.scores-section-info-bc.score.has-icon {
  position: relative;
}
.scores-section-info-bc.total-score {
  color: rgba(var(--b-sc-rgb), 0.9);
  font-weight: 700;
}
.scores-section-team-icon-bc {
  display: inline-block;
  vertical-align: top;
  width: 10px;
  height: 10px;
  font-size: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
[dir="ltr"] .scores-section-team-icon-bc {
  left: 0;
}
[dir="rtl"] .scores-section-team-icon-bc {
  right: 0;
}
.scores-section-team-icon-bc.t-1 {
  color: var(--oc-1);
}
.scores-section-team-icon-bc.t-2 {
  color: var(--b-sc);
}
.game-details-c-soccer-bc .game-details-c-body-name-bc {
  justify-content: space-around;
}
.game-details-c-soccer-bc .game-details-c-body-numbers-bc {
  flex-shrink: 0;
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
}
.scores-section-start-icon-bc {
  flex-shrink: 0;
  font-size: 8px;
  -webkit-margin-start: 4px;
  margin-inline-start: 4px;
  color: hsla(0, 0%, 100%, 0.9);
}
.game-details-basketballshots-shadow-bc {
  flex-shrink: 0;
  max-width: 45%;
  -webkit-padding-end: 6px;
  padding-inline-end: 6px;
  position: relative;
}
.game-details-basketballshots-shadow-bc:after {
  content: "";
  position: absolute;
  top: 0;
  width: 1px;
  height: 100%;
  box-shadow: inset 1px 0 0 0 #a9a9c1, -2px 0 5px 0 hsla(0, 0%, 100%, 0.8);
}
[dir="ltr"] .game-details-basketballshots-shadow-bc:after {
  right: 0;
}
[dir="rtl"] .game-details-basketballshots-shadow-bc:after {
  left: 0;
}
.game-details-numbers-bc {
  display: flex;
  will-change: scroll-position;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}
.game-details-numbers-bc::-webkit-scrollbar {
  display: none;
}
.game-details-c-body-bc.game-details-c-basketballshots-bc {
  align-items: center;
}
.game-details-c-body-bc.game-details-c-basketballshots-bc
  .game-details-c-b-r-c-score-item-bc
  + .game-details-c-b-r-c-score-item-bc {
  height: 19px;
  margin-top: 7px;
}
.game-details-c-body-bc.game-details-c-basketballshots-bc
  .game-details-c-body-numbers-bc
  + .game-details-c-body-numbers-bc {
  -webkit-margin-start: 15px;
  margin-inline-start: 15px;
}
.game-details-info-icon-bc {
  width: 17px;
  height: 17px;
  margin-top: 9px;
  text-align: center;
}
[class="game-details-info-icon-bc"] {
  background: #c2c5cd;
  border-radius: 50%;
}
.shooting-header {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.shooting-title {
  flex: auto;
  overflow: hidden;
}
.shooting-player {
  flex: auto;
  color: #fff;
  font-size: 14px;
}
.shooting-player + .shooting-player {
  margin-top: 8px;
}
.shooting-total-info {
  height: 28px;
  padding: 0 5px;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  text-transform: uppercase;
}
.shooting-total-info,
.shooting-total-score-block {
  flex-shrink: 0;
  -webkit-margin-start: 6px;
  margin-inline-start: 6px;
}
.shooting-total-score-block {
  display: flex;
}
.shooting-total-score {
  display: flex;
  flex-direction: column;
}
.shooting-total-score:first-child {
  color: #fff;
}
.shooting-total-score:nth-child(2) {
  color: hsla(0, 0%, 100%, 0.65);
  -webkit-margin-start: 5px;
  margin-inline-start: 5px;
  -webkit-padding-start: 4px;
  padding-inline-start: 4px;
}
[dir="ltr"] .shooting-total-score:nth-child(2) {
  border-left: 1px solid hsla(0, 0%, 100%, 0.1);
}
[dir="rtl"] .shooting-total-score:nth-child(2) {
  border-right: 1px solid hsla(0, 0%, 100%, 0.1);
}
.shooting-total-score-number {
  font-size: 14px;
  font-weight: 400;
}
.shooting-total-score-number + .shooting-total-score-number {
  margin-top: 8px;
}
.shooting-set-wrapper {
  display: flex;
  margin-top: 10px;
  padding: 10px 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}
.shooting-game-player {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding: 0 10px;
  position: -webkit-sticky;
  position: sticky;
}
[dir="ltr"] .shooting-game-player {
  left: 0;
  border-right: 1px solid hsla(0, 0%, 100%, 0.1);
}
[dir="rtl"] .shooting-game-player {
  right: 0;
  border-left: 1px solid hsla(0, 0%, 100%, 0.1);
}
.shooting-game-player-name {
  color: #fff;
  font-size: 12px;
}
.shooting-game-player-name + .shooting-game-player-name {
  margin-top: 10px;
}
.shooting-content {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  will-change: scroll-position;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}
.shooting-content::-webkit-scrollbar {
  display: none;
}
.shooting-game-block {
  flex-shrink: 0;
  display: flex;
  padding: 0 10px;
  height: 100%;
  overflow: hidden;
  position: relative;
  transition: width 0.24s;
  -webkit-border-end: 1px solid hsla(0, 0%, 100%, 0.1);
  border-inline-end: 1px solid hsla(0, 0%, 100%, 0.1);
}
.shooting-game-block:not(.shooting-set-expand) {
  width: 42px;
}
.shooting-game-block:not(.shooting-set-expand) .shooting-game-set {
  top: 50%;
  width: 24px;
  text-align: center;
}
[dir="ltr"] .shooting-game-block:not(.shooting-set-expand) .shooting-game-set {
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}
[dir="rtl"] .shooting-game-block:not(.shooting-set-expand) .shooting-game-set {
  right: 50%;
  -webkit-transform: translate3d(50%, -50%, 0);
  transform: translate3d(50%, -50%, 0);
}
.shooting-game-block.shooting-set-expand {
  width: auto;
}
.shooting-game-block.shooting-set-expand .shooting-game-set {
  top: 0;
}
[dir="ltr"] .shooting-game-block.shooting-set-expand .shooting-game-set {
  left: 10px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
[dir="rtl"] .shooting-game-block.shooting-set-expand .shooting-game-set {
  right: 10px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.shooting-game-block.shooting-set-expand .shooting-game-score {
  width: 100%;
}
.shooting-game-block.shooting-set-expand:last-child {
  -webkit-border-end: none;
  border-inline-end: none;
}
.shooting-game-set {
  color: #ccc;
  font-size: 10px;
  pointer-events: none;
  transition: all 0.24s;
}
.shooting-game-score {
  display: flex;
  align-items: center;
  width: 0;
  overflow: hidden;
  pointer-events: none;
  transition: width 0.24s;
}
.shooting-point {
  flex-shrink: 0;
  color: hsla(0, 0%, 100%, 0.95);
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  position: relative;
}
.shooting-point + .shooting-point {
  -webkit-margin-start: 4px;
  margin-inline-start: 4px;
}
.shooting-point:empty:not(.shooting-point-miss) {
  background: rgba(82, 82, 131, 0.4);
}
.shooting-point.active:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: #525283;
  border-radius: 50%;
  -webkit-animation: shooting-animation 1s linear 3;
  animation: shooting-animation 1s linear 3;
}
.live-score-bg-archery .shooting-point,
.live-score-bg-archeryh2h .shooting-point,
.live-score-bg-compoundarchery .shooting-point,
.live-score-bg-pistolshooting .shooting-point {
  width: 20px;
  height: 20px;
  line-height: 20px;
}
.live-score-bg-archery .shooting-game-set,
.live-score-bg-archeryh2h .shooting-game-set,
.live-score-bg-compoundarchery .shooting-game-set,
.live-score-bg-pistolshooting .shooting-game-set {
  position: absolute;
  width: 90%;
}
.shooting-point-total {
  background: #d6428e;
}
@-webkit-keyframes shooting-animation {
  0%,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@keyframes shooting-animation {
  0%,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.live-score-bg-archery .shooting-set-wrapper,
.live-score-bg-archeryh2h .shooting-set-wrapper,
.live-score-bg-compoundarchery .shooting-set-wrapper {
  height: 86px;
}
.live-score-bg-archery .shooting-point-5,
.live-score-bg-archery .shooting-point-6,
.live-score-bg-archeryh2h .shooting-point-5,
.live-score-bg-archeryh2h .shooting-point-6,
.live-score-bg-compoundarchery .shooting-point-5,
.live-score-bg-compoundarchery .shooting-point-6 {
  background: #1fb3eb;
}
.live-score-bg-archery .shooting-point-7,
.live-score-bg-archery .shooting-point-8,
.live-score-bg-archeryh2h .shooting-point-7,
.live-score-bg-archeryh2h .shooting-point-8,
.live-score-bg-compoundarchery .shooting-point-7,
.live-score-bg-compoundarchery .shooting-point-8 {
  background: #de232a;
}
.live-score-bg-archery .shooting-point-9,
.live-score-bg-archery .shooting-point-10,
.live-score-bg-archeryh2h .shooting-point-9,
.live-score-bg-archeryh2h .shooting-point-10,
.live-score-bg-compoundarchery .shooting-point-9,
.live-score-bg-compoundarchery .shooting-point-10 {
  background: #d69b42;
}
.live-score-bg-archery .shooting-point-miss,
.live-score-bg-archeryh2h .shooting-point-miss,
.live-score-bg-compoundarchery .shooting-point-miss {
  background: #626259;
}
.shootingh2h-container .shooting-game-block {
  flex-direction: column;
  justify-content: flex-end;
}
.shootingh2h-container .shooting-game-score + .shooting-game-score {
  margin-top: 5px;
}
.shooting-container .shooting-set-wrapper {
  height: 60px;
}
.shooting-container .shooting-game-block {
  align-items: flex-end;
}
.pistolshooting .shooting-set-wrapper {
  background: rgba(10, 15, 79, 0.52);
}
.pistolshooting .shooting-point-1,
.pistolshooting .shooting-point-2,
.pistolshooting .shooting-point-3,
.pistolshooting .shooting-point-4,
.pistolshooting .shooting-point-5,
.pistolshooting .shooting-point-6 {
  background: #fff;
  color: #0c1150;
  font-weight: 700;
  box-shadow: inset 0 0 0 1px #505050;
}
.pistolshooting .shooting-point-7,
.pistolshooting .shooting-point-8,
.pistolshooting .shooting-point-9,
.pistolshooting .shooting-point-10 {
  background: #0c1150;
  box-shadow: inset 0 0 0 1px #505050;
}
.pistolshooting .shooting-point-10 {
  text-indent: -1px;
}
.pistolshooting .shooting-point-miss {
  background: #505050;
  box-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, 0.5);
}
.pistolshooting .shooting-point:empty:not(.shooting-point-miss) {
  background: rgba(22, 85, 178, 0.3);
}
.pistolshooting .shooting-point.shooting-point-total {
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-indent: -1px;
}
.live-score-bg-pistolhead2head .shooting-game-player {
  justify-content: flex-start;
  padding-top: 23px;
}
.live-score-bg-pistolhead2head
  .shooting-game-player
  .shooting-game-player-name
  + .shooting-game-player-name {
  margin-top: 20px;
}
.live-score-bg-pistolhead2head .shooting-set-wrapper {
  height: 96px;
}
.live-score-bg-pistolhead2head .shooting-point {
  width: 26px;
  height: 26px;
  line-height: 26px;
}
.live-score-bg-pistolhead2head .shooting-point-1,
.live-score-bg-pistolhead2head .shooting-point-2,
.live-score-bg-pistolhead2head .shooting-point-3,
.live-score-bg-pistolhead2head .shooting-point-4,
.live-score-bg-pistolhead2head .shooting-point-5,
.live-score-bg-pistolhead2head .shooting-point-6 {
  background: #fff;
  color: #000;
}
.live-score-bg-pistolhead2head .shooting-point-7,
.live-score-bg-pistolhead2head .shooting-point-8,
.live-score-bg-pistolhead2head .shooting-point-9,
.live-score-bg-pistolhead2head .shooting-point-10 {
  background: #000;
  color: #fff;
}
.live-score-bg-pistolhead2head .shooting-point-miss {
  background: #505050;
  box-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, 0.5);
}
.live-score-bg-pistolhead2head .shooting-game-set {
  width: 100%;
  position: static;
  margin-bottom: 5px;
}
.live-score-bg-pistolhead2head .shooting-game-score {
  justify-content: center;
}
.sport-stream-bc {
  border-radius: 4px;
}
.animation-wrapper-bc {
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  padding: 0 10px;
  margin-bottom: 10px;
}
.animation-wrapper-bc iframe {
  border-radius: 4px;
}
.kiev-animation {
  padding-top: 55.6176%;
  position: relative;
}
@media screen and (max-width: 420px) {
  .kiev-animation {
    padding-top: 58.6176%;
  }
}
.kiev-animation .bc-loader {
  position: absolute;
}
.kiev-animation.has-statistic {
  padding-top: calc(58.6176% + 70px);
}
.kiev-animation > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
audio::-webkit-media-controls-timeline,
video::-webkit-media-controls-timeline {
  display: none;
}
.video-pr-bc {
  display: inline-flex;
  min-width: 140px;
}
.video-pr-bc:not(:only-child) {
  margin-bottom: 8px;
}
.video-pr-bc .select-option-bc {
  padding: 0;
}
.time-line-container-bc {
  height: 70px;
  padding: 0 7px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAWMAAABGCAYAAAAZ1PXQAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAABY6ADAAQAAAABAAAARgAAAABbemiPAAACv0lEQVR4Ae3UIWoDYRhF0TSmdV1QbBbWLcelqtX/04FL4Ix7I+aDM3A/7j+3v0v4fH1/htfP08/H7/niTRfT1/84pkxfL3B+8XpOiwABAgQKATEu1N0kQIDACIjxgJgECBAoBMS4UHeTAAECIyDGA2ISIECgEBDjQt1NAgQIjIAYD4hJgACBQkCMC3U3CRAgMAJiPCAmAQIECgExLtTdJECAwAiI8YCYBAgQKATEuFB3kwABAiMgxgNiEiBAoBAQ40LdTQIECIyAGA+ISYAAgUJAjAt1NwkQIDACYjwgJgECBAoBMS7U3SRAgMAIiPGAmAQIECgExLhQd5MAAQIjIMYDYhIgQKAQEONC3U0CBAiMgBgPiEmAAIFCQIwLdTcJECAwAmI8ICYBAgQKATEu1N0kQIDACIjxgJgECBAoBMS4UHeTAAECIyDGA2ISIECgEBDjQt1NAgQIjIAYD4hJgACBQkCMC3U3CRAgMAJiPCAmAQIECgExLtTdJECAwAiI8YCYBAgQKATEuFB3kwABAiMgxgNiEiBAoBAQ40LdTQIECIyAGA+ISYAAgUJAjAt1NwkQIDACYjwgJgECBAoBMS7U3SRAgMAIiPGAmAQIECgExLhQd5MAAQIjIMYDYhIgQKAQEONC3U0CBAiMgBgPiEmAAIFCQIwLdTcJECAwAmI8ICYBAgQKATEu1N0kQIDACIjxgJgECBAoBMS4UHeTAAECIyDGA2ISIECgEBDjQt1NAgQIjIAYD4hJgACBQkCMC3U3CRAgMAJiPCAmAQIECgExLtTdJECAwAiI8YCYBAgQKATEuFB3kwABAiMgxgNiEiBAoBAQ40LdTQIECIyAGA+ISYAAgUJAjAt1NwkQIDACYjwgJgECBAoBMS7U3SRAgMAIiPGAmAQIECgExLhQd5MAAQIjIMYDYhIgQKAQ+AdswA6KjfQz8QAAAABJRU5ErkJggg==)
    no-repeat 0 0 / cover;
  border-radius: 4px;
}
.time-line-content-bc {
  height: 100%;
}
.time-line-content-bc,
.time-line-separators-bc {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}
.time-line-separators-bc {
  justify-content: space-between;
  height: 30px;
  z-index: 1;
}
.time-line-separators-bc > li {
  width: 1px;
  background: #fff;
  border-radius: 1px;
  position: relative;
}
.time-line-separators-bc > li:nth-child(odd) {
  height: 100%;
}
.time-line-separators-bc > li:nth-child(2n) {
  height: 12px;
}
.time-line-separators-bc > li:first-child:before,
.time-line-separators-bc > li:last-child:before {
  content: attr(data-time);
  position: absolute;
  top: -12px;
  font-size: 8px;
  white-space: nowrap;
  border-radius: 2px;
  color: #4c8f42;
  width: 14px;
  line-height: 10px;
  text-align: center;
  background: hsla(0, 0%, 100%, 0.6);
}
.time-line-separators-bc > li:first-child:before {
  left: -6px;
}
.time-line-separators-bc > li:last-child:before {
  left: -7px;
}
.time-line-separators-bc > li.bc-i-half-time:before {
  position: absolute;
  top: -15px;
  left: -6px;
  font-size: 12px;
  color: hsla(0, 0%, 100%, 0.6);
}
.time-line-separators-bc:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background: hsla(0, 0%, 100%, 0.5);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.time-line-match-bc {
  height: 18px;
  border-radius: 1px;
  background: hsla(0, 0%, 100%, 0.3);
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -9px;
}
.time-line-icons-bc {
  width: 100%;
  height: 1px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}
.time-line-icons-bc > li {
  position: absolute;
}
.time-line-icons-bc > li.red {
  color: var(--oc-3);
}
.time-line-icons-bc > li.yellow {
  color: var(--oc-2);
}
.game-events-list-block-bc {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  background: var(--b);
  overflow: hidden;
  border-radius: 4px;
}
.game-events-list-block-bc .empty-b-text-v-bc {
  background: rgba(var(--b-sc-rgb), 0.05);
}
.game-events-list-title-bc {
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 10px;
  color: rgba(var(--b-sc-rgb), 0.9);
  background: rgba(var(--b-sc-rgb), 0.15);
  cursor: pointer;
}
.game-events-list-title-bc.active .game-events-list-title-icon-bc {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
.game-events-list-text-bc {
  flex: auto;
  font-size: 12px;
  font-weight: 400;
  -webkit-margin-end: 10px;
  margin-inline-end: 10px;
}
.game-events-list-title-icon-bc {
  flex-shrink: 0;
  font-size: 10px;
}
.game-events-list-bc {
  display: flex;
  flex-direction: column;
  max-height: 164px;
  margin-top: 1px;
  padding: 0 10px;
  background: rgba(var(--b-sc-rgb), 0.1);
  will-change: scroll-position;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.game-events-list-info-bc {
  display: flex;
  align-items: center;
  height: 32px;
  min-height: 32px;
}
.game-events-list-info-bc + .game-events-list-info-bc {
  box-shadow: 0 -1px 0 0 var(--b);
}
.game-events-list-icon-bc {
  flex-shrink: 0;
  font-size: 12px;
  color: var(--b-sc);
  -webkit-margin-end: 5px;
  margin-inline-end: 5px;
}
.game-events-list-icon-bc + .game-events-list-icon-bc {
  -webkit-padding-start: 5px;
  padding-inline-start: 5px;
}
[dir="ltr"] .game-events-list-icon-bc + .game-events-list-icon-bc {
  box-shadow: -1px 0 0 0 var(--b);
}
[dir="rtl"] .game-events-list-icon-bc + .game-events-list-icon-bc {
  box-shadow: 1px 0 0 0 var(--b);
}
.game-events-list-icon-bc.red {
  color: var(--oc-3);
}
.game-events-list-icon-bc.yellow {
  color: var(--oc-2);
}
.game-events-list-icon-bc .bc-i-players {
  color: rgba(var(--b-sc-rgb), 0.8);
}
.game-events-list-info-title-bc {
  flex: auto;
  color: rgba(var(--b-sc-rgb), 0.7);
  -webkit-margin-start: 5px;
  margin-inline-start: 5px;
  font-size: 12px;
}
.suggested-events-widget {
  width: 100%;
  height: 180px;
  position: relative;
}
.suggested-events-widget > iframe {
  width: 100%;
  height: 100%;
}
.suggested-events-widget .bc-loader {
  position: absolute;
}
.express-of-the-day .competition-wrapper-bc {
  position: relative;
  border: 1px solid rgba(var(--b-sc-rgb), 0.2);
  border-radius: 4px;
}
.express-of-the-day .competition-wrapper-bc .express-day-loader {
  position: absolute;
  top: 34px;
  left: 0;
  width: 100%;
  height: calc(100% - 34px);
  background: rgba(var(--b-rgb), 0.7);
  z-index: 1;
}
.express-of-the-day .competition-wrapper-bc + .competition-wrapper-bc {
  margin-top: 15px;
}
.express-of-the-day .competition-wrapper-bc .bs-inputs-bc {
  background: rgba(var(--b-sc-rgb), 0.05);
}
.express-of-the-day .bs-info-massages-w-bc,
.express-of-the-day .fixed-bottom-container > .btn {
  margin-top: 10px;
}
.express-of-the-day .express-day-block + .express-show-events-padding {
  padding-top: 5px;
}
.express-day-block {
  padding: 0 5px 5px;
  background: rgba(var(--b-sc-rgb), 0.05);
}
.express-day-block:nth-last-child(2) {
  padding-bottom: 10px;
}
.express-day-content {
  padding: 6px 8px;
  background: rgba(var(--b-sc-rgb), 0.05);
  border-radius: 4px 4px 0 0;
}
.express-day-content .quickBetMsgWrapper-bc {
  margin-bottom: 6px;
}
.express-day-info {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.express-day-icon {
  flex-shrink: 0;
  -webkit-margin-end: 8px;
  margin-inline-end: 8px;
  color: rgba(var(--b-sc-rgb), 0.8);
  font-size: 16px;
}
.express-day-game {
  flex: auto;
  margin: 0 8px;
  color: var(--b-sc);
  font-size: 14px;
}
.express-day-statistics-icon {
  flex-shrink: 0;
  color: rgba(var(--b-sc-rgb), 0.6);
  cursor: pointer;
  font-size: 16px;
}
.express-day-team {
  color: rgba(var(--b-sc-rgb), 0.7);
  font-size: 12px;
}
.express-day-team + .express-day-team {
  margin-top: 4px;
}
.express-day-player-section {
  display: flex;
  align-items: center;
  height: 36px;
  margin-top: 8px;
  padding: 0 10px;
  background: rgba(var(--b-sc-rgb), 0.03);
  border-radius: 4px;
}
.express-day-player {
  flex: auto;
  color: rgba(var(--b-sc-rgb), 0.7);
  font-size: 12px;
}
.express-day-player-odd {
  flex-shrink: 0;
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
  color: var(--oc-2);
  font-size: 12px;
  font-weight: 400;
}
.express-day-player-odd.remove {
  color: var(--oc-3);
  text-decoration: line-through;
}
.express-day-footer {
  display: flex;
  align-items: center;
  height: 24px;
  margin-top: 1px;
  padding: 0 5px;
  background: rgba(var(--b-sc-rgb), 0.05);
  color: rgba(var(--b-sc-rgb), 0.5);
  border-radius: 0 0 4px 4px;
  font-size: 12px;
}
.express-day-time {
  flex: auto;
}
.express-day-more {
  flex-shrink: 0;
  font-weight: 400;
}
.express-bets {
  padding: 2px 10px 10px;
  color: var(--oc-1);
  background: rgba(var(--b-sc-rgb), 0.05);
  font-size: 12px;
  font-weight: 500;
}
.express-show-events-padding {
  padding: 0 10px;
  background: rgba(var(--b-sc-rgb), 0.05);
}
.favorite-team-content {
  position: relative;
}
.favorite-team-content .sport-search-result-item-bc {
  display: flex;
  align-items: center;
}
.favorite-team-content .single-g-info-bc {
  margin-bottom: 5px;
}
.favorite-team-content .sg-head-bc {
  display: none;
}
.favorite-team-content .market-filter-bc {
  margin-bottom: 3px;
}
.favorite-search-result-img {
  max-width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.favorite-search-result-text {
  flex: auto;
  font-weight: 500;
  color: var(--b-sc);
}
.favorite-single-item {
  background: rgba(var(--b-sc-rgb), 0.05);
  border-radius: 4px;
  border: 1px solid rgba(var(--b-sc-rgb), 0.2);
}
.favorite-single-item-content {
  display: flex;
  align-items: center;
}
.favorite-team .bc-i-valentine {
  color: #f90;
}
.favorite-team.a-color {
  background: linear-gradient(95deg, #f90, #ffb800 97%);
}
.favorite-team.a-color .bc-i-valentine {
  color: #fff;
}
.favorite-team.a-color:active {
  background: linear-gradient(95deg, #f90, #ffb800 97%);
}
.competition-details-section {
  border-radius: 4px;
  overflow: hidden;
}
.competition-details-section + .competition-details-section {
  margin-top: 2px;
}
.competition-details-header {
  display: flex;
  align-items: center;
  min-height: 34px;
  margin-bottom: 1px;
  padding: 5px 10px;
  background: rgba(var(--b-sc-rgb), 0.15);
  cursor: pointer;
  transition: background 0.24s;
}
.competition-details-header p {
  color: var(--b-sc);
  font-size: 12px;
  font-weight: 500;
}
.competition-details-header p:not(:only-child) {
  max-width: 48%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.competition-details-header span {
  margin: 0 8px;
  color: rgba(var(--b-sc-rgb), 0.5);
  font-size: 11px;
}
.competition-details-header.active,
.competition-details-header:hover {
  background: rgba(var(--b-sc-rgb), 0.25);
}
.competition-details-odds {
  display: flex;
}
.competition-details-info-container {
  display: flex;
  align-items: center;
  height: 24px;
  margin-top: 1px;
  padding: 0 5px;
  color: rgba(var(--b-sc-rgb), 0.5);
  background: rgba(var(--b-sc-rgb), 0.1);
  cursor: pointer;
  font-size: 11px;
}
.competition-details-info-container i {
  flex-shrink: 0;
  color: rgba(var(--b-sc-rgb), 0.8);
}
.competition-details-info-container b {
  flex-shrink: 0;
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
  cursor: pointer;
  font-weight: 400;
}
.competition-details-info-time {
  flex: auto;
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
}
.competition-details-more-icon,
.competition-details-more-icon + .notification-loader {
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
  -webkit-padding-end: 10px;
  padding-inline-end: 10px;
  cursor: pointer;
}
[dir="ltr"]
  .competition-details-more-icon
  + .notification-loader:not(:only-child),
[dir="ltr"] .competition-details-more-icon:not(:only-child) {
  box-shadow: 1px 0 0 0 rgba(var(--b-sc-rgb), 0.1);
}
[dir="rtl"]
  .competition-details-more-icon
  + .notification-loader:not(:only-child),
[dir="rtl"] .competition-details-more-icon:not(:only-child) {
  box-shadow: -1px 0 0 0 rgba(var(--b-sc-rgb), 0.1);
}
.multi-column-toggle-title {
  flex-shrink: 0;
  margin: 0 5px;
  color: rgba(var(--b-sc-rgb), 0.8);
  font-size: 12px;
}
.multi-column-view .comp-fav-icon-bc {
  order: -1;
  -webkit-margin-start: 0;
  margin-inline-start: 0;
  -webkit-margin-end: 10px;
  margin-inline-end: 10px;
}
.multi-column-view.multi-column-view-expanded .multi-column-resize-block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--b);
  z-index: 10;
  will-change: scroll-position;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.multi-column-view.multi-column-view-expanded .comp-name-fav-row-bc {
  width: calc(100% - 2px);
}
.multi-column-view.multi-column-view-expanded .multi-column-container {
  -webkit-padding-end: 2px;
  padding-inline-end: 2px;
}
.multi-column-container,
.multi-column-view:not(.multi-column-view-expanded) .prematch-page-bc {
  position: relative;
}
.multi-column-to-icon {
  position: absolute;
  top: -28px;
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
  color: rgba(var(--b-sc-rgb), 0.8);
  transition: 0.24s;
}
[dir="ltr"] .multi-column-to-icon {
  right: 10px;
}
[dir="rtl"] .multi-column-to-icon {
  left: 10px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
@media (hover: hover) {
  .multi-column-to-icon:hover {
    color: var(--b-sc);
    cursor: pointer;
  }
}
@media not all and (hover: hover) {
  .multi-column-to-icon:active {
    color: var(--b-sc);
  }
}
.multi-column-header {
  display: flex;
  align-items: center;
  height: 52px;
  margin-top: 1px;
  padding-top: 8px;
  padding-bottom: 8px;
  -webkit-padding-start: 10px;
  padding-inline-start: 10px;
  -webkit-padding-end: 5px;
  padding-inline-end: 5px;
  color: var(--b-sc);
  background: rgba(var(--b-sc-rgb), 0.15);
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
}
.multi-column-header > li {
  height: 100%;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
}
.multi-column-header > li > div {
  display: flex;
}
.multi-column-header > li > div > b {
  width: 100%;
  font-weight: 500;
}
.multi-column-header > li:first-child {
  flex: auto;
}
.multi-column-header > li:last-child {
  -webkit-margin-start: 5px;
  margin-inline-start: 5px;
  width: 50px;
}
.multi-column-WINNER {
  width: 152px;
  flex-shrink: 0;
}
.multi-column-WINNER.multi-column-count-2 {
  width: 101px;
}
.multi-column-HANDICAP,
.multi-column-TOTALS {
  flex-shrink: 0;
  width: 102px;
}
.multi-column-show-type-OUTRIGHT {
  height: 44px;
}
.multi-column-show-type-OUTRIGHT .multi-column-teams,
.multi-column-show-type-OUTRIGHT .multi-column-time-icon {
  align-items: center;
}
.multi-column-show-type-OUTRIGHT .multi-column-time-icon > div {
  display: flex;
}
.multi-column-show-type-OUTRIGHT .multi-column-time-icon i {
  line-height: 12px;
  -webkit-margin-start: 5px;
  margin-inline-start: 5px;
}
.multi-column-content {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-padding-start: 10px;
  padding-inline-start: 10px;
  -webkit-padding-end: 5px;
  padding-inline-end: 5px;
  background: rgba(var(--b-sc-rgb), 0.1);
  border-radius: 4px;
  transition: background 0.24s;
}
.multi-column-content:not(.multi-column-show-type-OUTRIGHT) {
  height: 54px;
}
.multi-column-content:not(.multi-column-show-type-OUTRIGHT) .multi-column-teams,
.multi-column-content:not(.multi-column-show-type-OUTRIGHT)
  .multi-column-time-icon {
  flex-direction: column;
}
.multi-column-content.active,
.multi-column-content:hover {
  background: rgba(var(--b-sc-rgb), 0.15);
}
.multi-column-content > li {
  height: 100%;
  display: flex;
  align-items: center;
}
.multi-column-content > li .market-bc {
  width: 44px;
}
.multi-column-content > li .market-bc + .market-bc {
  -webkit-margin-start: 3px;
  margin-inline-start: 3px;
}
.multi-column-content > li:first-child {
  flex: auto;
  justify-content: space-between;
  overflow: hidden;
  -webkit-margin-end: 8px;
  margin-inline-end: 8px;
  cursor: pointer;
}
.multi-column-content > li:last-child {
  flex-shrink: 0;
  -webkit-margin-start: 5px;
  margin-inline-start: 5px;
  width: 50px;
  color: rgba(var(--b-sc-rgb), 0.5);
  font-size: 12px;
  transition: 0.24s;
}
@media (hover: hover) {
  .multi-column-content > li:last-child:hover {
    color: rgba(var(--b-sc-rgb), 0.8);
    cursor: pointer;
  }
}
@media not all and (hover: hover) {
  .multi-column-content > li:last-child:active {
    color: rgba(var(--b-sc-rgb), 0.8);
  }
}
.multi-column-content > li:last-child i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: rgba(var(--b-sc-rgb), 0.8);
}
[dir="rtl"] .multi-column-content > li:last-child i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.multi-column-content .multi-column-base,
.multi-column-content > li .market-bc {
  border-radius: 4px;
}
.multi-column-content .market-odd-bc,
.multi-column-content .multi-column-base {
  font-size: 11px;
}
.multi-column-content .multi-column-base {
  justify-content: center;
  color: var(--b-sc);
  border: 1px solid rgba(var(--b-sc-rgb), 0.3);
}
.multi-column-content + .multi-column-content {
  margin-top: 2px;
}
.multi-column-icons {
  display: flex;
  align-items: center;
}
.multi-column-base {
  flex-shrink: 0;
  width: 49px;
  -webkit-margin-start: 8px;
  margin-inline-start: 8px;
  -webkit-margin-end: 3px;
  margin-inline-end: 3px;
}
.multi-column-teams,
.multi-column-time-icon {
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.multi-column-teams {
  color: var(--b-sc);
  -webkit-margin-end: 10px;
  margin-inline-end: 10px;
  overflow: hidden;
  font-size: 12px;
  font-weight: 500;
}
.multi-column-time-icon time {
  display: flex;
  width: 100%;
  color: rgba(var(--b-sc-rgb), 0.5);
  font-size: 11px;
}
.multi-column-time-icon i {
  display: inline-flex;
  color: rgba(var(--b-sc-rgb), 0.8);
  font-size: 11px;
  transition: 0.24s;
}
@media (hover: hover) {
  .multi-column-time-icon i:hover {
    color: var(--b-sc);
    cursor: pointer;
  }
}
@media not all and (hover: hover) {
  .multi-column-time-icon i:active {
    color: var(--b-sc);
  }
}
.multi-column-time-icon i ~ i,
.multi-column-time-icon i ~ p {
  -webkit-margin-start: 5px;
  margin-inline-start: 5px;
}
.multi-column-time-icon
  .notification-loader
  .bc-loader.small
  .bc-loader-contain {
  max-width: 11px;
  height: 11px;
  line-height: 11px;
}
.multi-column-time-icon .notification-loader .bc-loader.small .vivaro-loader {
  width: 11px;
  max-height: 11px;
}
.live-calendar .bet-builder-container .bet-builder-text,
.prematch-page-bc .bet-builder-container .bet-builder-text,
.special-games-container .bet-builder-container .bet-builder-text {
  display: none;
}
@media screen and (max-width: 1980px) {
  .multi-column-view .prematch-page-bc:not(:only-child) .comp-name-fav-row-bc {
    -webkit-padding-end: 37px;
    padding-inline-end: 37px;
  }
}
@media screen and (max-width: 1760px) {
  .multi-column-view:not(.sportsbook-small-view, .multi-column-view-expanded)
    .multi-column-base:nth-child(3),
  .multi-column-view:not(.sportsbook-small-view, .multi-column-view-expanded)
    .multi-column-base:nth-child(3)
    + li {
    display: none;
  }
}
@media screen and (max-width: 1490px) {
  .multi-column-view:not(.sportsbook-small-view, .multi-column-view-expanded)
    .multi-column-base:nth-last-child(3),
  .multi-column-view:not(.sportsbook-small-view, .multi-column-view-expanded)
    .multi-column-base:nth-last-child(3)
    + li {
    display: none;
  }
}
@media screen and (min-width: 1440px) {
  .multi-column-view:not(.multi-column-view-expanded)
    .prematch-page-bc:not(:only-child) {
    width: 57%;
  }
  .multi-column-view:not(.multi-column-view-expanded)
    .game-details-section:not(:only-child) {
    width: 43%;
  }
}
@media screen and (max-width: 1290px) {
  .multi-column-view .multi-column-base:nth-last-child(3),
  .multi-column-view .multi-column-base:nth-last-child(3) + li {
    display: none;
  }
}
@media screen and (max-width: 1130px) {
  .multi-column-view .multi-column-base:nth-child(3),
  .multi-column-view .multi-column-base:nth-child(3) + li {
    display: none;
  }
}
.competition-details-info-container .notification-loader .bc-loader.small {
  margin: 0;
}
.competition-details-info-container
  .notification-loader
  .bc-loader.small
  .bc-loader-contain {
  max-width: 11px;
  height: 11px;
  line-height: 11px;
}
.competition-details-info-container
  .notification-loader
  .bc-loader.small
  .vivaro-loader {
  width: 11px;
  max-height: 11px;
}
.tournament-select {
  padding: 0 7px;
}
.tournament-select .form-control-label-bc.inputs {
  height: 40px;
}
.tournament-select .select-icon-bc {
  position: absolute;
  top: 0;
  line-height: 39px;
  color: hsla(0, 0%, 100%, 0.8);
  font-size: 18px;
  pointer-events: none;
}
[dir="ltr"] .tournament-select .select-icon-bc {
  left: 12px;
}
[dir="rtl"] .tournament-select .select-icon-bc {
  right: 12px;
}
.tournament-select .form-control-select-bc {
  padding-top: 16px;
  -webkit-padding-start: 38px;
  padding-inline-start: 38px;
  -webkit-padding-end: 40px;
  padding-inline-end: 40px;
  color: hsla(0, 0%, 100%, 0.9);
  background: linear-gradient(270deg, #8d17c7, #031778);
}
.tournament-select .form-control-bc:not(:only-child) .form-control-select-bc {
  border-radius: 4px 4px 0 0;
}
.tournament-select .form-control-bc.filled .form-control-title-bc {
  top: 5px;
  font-size: 10px;
  line-height: 11px;
  color: hsla(0, 0%, 100%, 0.6);
  text-transform: uppercase;
}
[dir="ltr"] .tournament-select .form-control-bc.filled .form-control-title-bc {
  left: 38px;
}
[dir="rtl"] .tournament-select .form-control-bc.filled .form-control-title-bc {
  right: 38px;
}
.tournament-select .form-control-icon-bc {
  color: hsla(0, 0%, 100%, 0.8);
}
.tournament-details {
  padding: 10px;
  background: rgba(var(--b-sc-rgb), 0.05);
  border-radius: 0 0 4px 4px;
}
.tournament-details-header {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.tournament-details-remove {
  flex-shrink: 0;
  font-size: 8px;
  color: rgba(var(--b-sc-rgb), 0.6);
  -webkit-margin-start: 8px;
  margin-inline-start: 8px;
}
.tournament-details-price {
  color: var(--oc-1);
  font-weight: 700;
  flex: auto;
  font-size: 12px;
  -webkit-margin-end: 3px;
  margin-inline-end: 3px;
}
.btn.tournament-details-reset {
  flex-shrink: 0;
  font-size: 12px;
  background: none;
  text-transform: none;
  padding: 0;
  width: auto;
  height: auto;
}
.btn.tournament-details-reset,
.tournament-details-body {
  color: rgba(var(--b-sc-rgb), 0.6);
}
.tournament-details-body-text {
  font-size: 10px;
  line-height: 11px;
}
.tournament-details-body-time-bc {
  font-size: 10px;
  display: flex;
  align-items: center;
  margin-top: 4px;
}
.tournament-details-time-bc {
  display: flex;
  align-items: center;
  flex: auto;
  -webkit-margin-end: 3px;
  margin-inline-end: 3px;
}
.tournament-details-arrow-to {
  margin: 0 5px;
  font-size: 7px;
}
.tournament-details-bc {
  flex-shrink: 0;
  color: rgba(var(--b-sc-rgb), 0.6);
  font-size: 12px;
  text-decoration: underline;
}
.special-games-button {
  display: flex;
  margin-top: 15px;
  padding: 0 7px;
  will-change: scroll-position;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}
.special-games-button::-webkit-scrollbar {
  display: none;
}
.special-games-button .btn {
  text-transform: none;
}
.special-games-button .btn > i {
  font-size: 14px;
}
.special-games-button .btn + .btn {
  -webkit-margin-start: 5px;
  margin-inline-start: 5px;
}
.filter-sports-bc {
  display: flex;
  align-items: center;
  position: relative;
  height: 36px;
  margin: 5px 0;
  -webkit-padding-start: 7px;
  padding-inline-start: 7px;
  -webkit-padding-end: 52px;
  padding-inline-end: 52px;
}
.filter-sports-bc .form-control-bc + .form-control-bc {
  max-width: 46%;
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
}
.filter-sports-bc .form-control-bc + .btn {
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
}
.filter-sports-bc .form-control-bc:first-child:nth-last-child(3) {
  max-width: 48%;
}
.filter-sports-bc .form-control-bc:first-child:nth-last-child(4) {
  max-width: 40%;
}
.filter-sports-bc > .btn {
  width: auto;
}
.filter-sports-bc > .btn + .btn {
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
}
.filter-icon-bc {
  font-size: 14px;
}
.sg-head-bc {
  border-radius: 4px;
}
.left-menu-scroll .sp-s-l-b-content-bc .v-checkbox {
  position: relative;
  border-radius: 4px;
}
.left-menu-scroll .sp-s-l-b-content-bc .v-checkbox .sp-s-l-h-title-content {
  -webkit-padding-start: 26px;
  padding-inline-start: 26px;
}
.left-menu-scroll
  .sp-s-l-b-content-bc
  .v-checkbox
  .sp-s-l-h-title-content:before {
  content: "";
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: rgba(var(--b-sc-rgb), 0.2);
  border-radius: 4px;
  transition: background 0.4s;
  z-index: 0;
}
.left-menu-scroll .sp-s-l-b-content-bc .v-checkbox + .v-checkbox {
  margin-top: 2px;
}
.left-menu-scroll
  .sp-s-l-b-content-bc
  .v-checkbox.checked
  .sp-s-l-h-title-content:before {
  font-family: "BetConstruct-Icons";
  content: "о—Љ";
  font-size: 9px;
  background: var(--hero);
  color: var(--b-sc);
}
.popular-bets-table-row {
  border-radius: 4px;
  overflow: hidden;
}
.popular-bets-table-body-cell .c-teams-bc {
  background: none;
}
.popular-bets-match-time {
  line-height: 18px;
  color: rgba(var(--b-sc-rgb), 0.5);
}
.popular-bets-match-time .match-time {
  -webkit-border-end: 1px solid rgba(var(--b-sc-rgb), 0.1);
  border-inline-end: 1px solid rgba(var(--b-sc-rgb), 0.1);
}
.popular-bets-content .team {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.current-day-1:after {
  content: "1";
}
.current-day-2:after {
  content: "2";
}
.current-day-3:after {
  content: "3";
}
.current-day-4:after {
  content: "4";
}
.current-day-5:after {
  content: "5";
}
.current-day-6:after {
  content: "6";
}
.current-day-7:after {
  content: "7";
}
.current-day-8:after {
  content: "8";
}
.current-day-9:after {
  content: "9";
}
.current-day-10:after {
  content: "10";
}
.current-day-11:after {
  content: "11";
}
.current-day-12:after {
  content: "12";
}
.current-day-13:after {
  content: "13";
}
.current-day-14:after {
  content: "14";
}
.current-day-15:after {
  content: "15";
}
.current-day-16:after {
  content: "16";
}
.current-day-17:after {
  content: "17";
}
.current-day-18:after {
  content: "18";
}
.current-day-19:after {
  content: "19";
}
.current-day-20:after {
  content: "20";
}
.current-day-21:after {
  content: "21";
}
.current-day-22:after {
  content: "22";
}
.current-day-23:after {
  content: "23";
}
.current-day-24:after {
  content: "24";
}
.current-day-25:after {
  content: "25";
}
.current-day-26:after {
  content: "26";
}
.current-day-27:after {
  content: "27";
}
.current-day-28:after {
  content: "28";
}
.current-day-29:after {
  content: "29";
}
.current-day-30:after {
  content: "30";
}
.current-day-31:after {
  content: "31";
}
.popular-bets-center-section {
  overflow-y: hidden;
}
.popular-bets-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 680px;
}
.popular-bets-table .popular-bets-table-container-virtuoso {
  overflow-x: hidden;
}
.popular-bets-table-head-cell {
  min-height: 28px;
  padding: 7px;
  font-size: 12px;
  background: rgba(var(--b-sc-rgb), 0.15);
  color: var(--b-sc);
}
.popular-bets-table-row {
  display: grid;
  grid-template-columns: 1fr repeat(3, 110px) 70px;
  margin-bottom: 2px;
  min-width: 680px;
}
.popular-bets-table-row:not(.popular-bets-table-header) {
  grid-column-gap: 1px;
}
.popular-bets-table-body-cell {
  display: flex;
  align-items: center;
  background: rgba(var(--b-sc-rgb), 0.1);
  -webkit-padding-start: 10px;
  padding-inline-start: 10px;
  -webkit-padding-end: 5px;
  padding-inline-end: 5px;
  height: 56px;
  min-width: 0;
}
.popular-bets-table-body-cell:last-child {
  padding: 0;
}
.popular-bets-content {
  width: calc(100% - 95px);
  display: flex;
  align-items: flex-end;
}
.popular-bets-match-time {
  width: 95px;
  flex-shrink: 0;
  font-size: 13px;
  display: flex;
  align-items: center;
}
.popular-bets-match-time .bc-i-prematch {
  font-size: 14px;
  -webkit-margin-end: 8px;
  margin-inline-end: 8px;
  color: rgba(var(--b-sc-rgb), 0.8);
}
.popular-bets-bet,
.popular-bets-market {
  font-size: 12px;
  line-height: 18px;
  justify-content: center;
  text-align: center;
}
.popular-bets-market {
  color: rgba(var(--b-sc-rgb), 0.5);
}
.popular-bets-bet {
  color: var(--b-sc);
}
.popular-bets-competition {
  font-size: 13px;
  width: 24px;
  text-align: center;
  cursor: pointer;
}
.popular-bets-competition.bc-i-favorite {
  color: rgba(var(--b-sc-rgb), 0.7);
  transition: 0.24s;
}
@media (hover: hover) {
  .popular-bets-competition.bc-i-favorite:hover {
    color: rgba(var(--b-sc-rgb), 0.9);
    cursor: pointer;
  }
}
@media not all and (hover: hover) {
  .popular-bets-competition.bc-i-favorite:active {
    color: rgba(var(--b-sc-rgb), 0.9);
  }
}
.popular-bets-competition.bc-i-star {
  color: var(--b-sc);
}
