.container {
    background-color: black;
    color: white;
    text-align: center;
    padding-top: 50px;
  }
  
  .title {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .message {
    font-size: 24px;
    margin-bottom: 40px;
  }
  
  .button {
    background-color: white;
    color: black;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
  }
  