.custom-footer-text {
 font-size: 12px;
 color: #ffffff66;
 line-height: 14px;
}

.links_container {
 display: flex;
 flex-direction: row;
 margin-top: 20px;
 justify-content: center;
}

.footer_social {
 width: 40px;
 height: 40px;
 margin-right: 20px;
 cursor: pointer;
}
