.sp-fav-head-bc {
  width: 100%;
  height: 24px;
}
.sp-fav-h-side-bc,
.sp-fav-head-bc {
  display: inline-block;
  vertical-align: top;
}
.sp-fav-h-side-bc {
  height: 100%;
}
[dir="ltr"] .sp-fav-h-side-bc.left {
  float: left;
}
[dir="ltr"] .sp-fav-h-side-bc.right,
[dir="rtl"] .sp-fav-h-side-bc.left {
  float: right;
}
[dir="rtl"] .sp-fav-h-side-bc.right {
  float: left;
}
.sp-fav-h-tabs-holder-bc {
  height: 100%;
  white-space: nowrap;
  border-radius: 4px 4px 0 0;
  background-color: #2b2b40;
  background-color: var(--skin-palette-5);
  box-shadow: inset 0 -2px 0 0 #353550;
  box-shadow: 0 -2px 0 0 var(--skin-palette-6) inset;
}
.sp-fav-h-tab-bc {
  display: inline-block;
  vertical-align: top;
  height: 100%;
  padding: 0 8px;
  font-weight: 700;
  font-size: 14px;
  color: #b3b3b3;
  color: var(--text-palette-15);
  line-height: 24px;
  text-transform: capitalize;
  transition-property: background-color, color, box-shadow;
  transition-duration: 0.24s;
  cursor: pointer;
}
.sp-fav-h-tab-bc.active,
.sp-fav-h-tab-bc:hover {
  background-color: #212131;
  background-color: var(--skin-palette-4);
  color: var(--b-sc);
}
.sp-fav-h-tab-bc.active {
  box-shadow: 0 -2px 0 0 var(--oc-1) inset;
}
.sp-fav-h-clear-btn-bc {
  display: inline-block;
  vertical-align: middle;
  height: 14px;
  font-weight: 400;
  font-size: 12px;
  color: #bfbfbf;
  color: var(--text-palette-16);
  white-space: nowrap;
  line-height: 14px;
  transition: color 0.24s ease;
  cursor: pointer;
}
.sp-fav-h-clear-btn-bc:first-letter {
  text-transform: capitalize;
}
.sp-fav-h-clear-btn-bc:hover {
  color: var(--b-sc);
}
.sp-fav-item-holder-bc {
  margin-bottom: 8px;
}
.sp-fav-item-holder-bc:last-child {
  margin-bottom: 0;
}
.popular-competitions-bc {
  overflow: hidden;
}
.sp-s-l-b-c-i-fav-icon-bc {
  flex-shrink: 0;
  height: 25px;
  -webkit-padding-end: 10px;
  padding-inline-end: 10px;
  color: rgba(var(--b-sc-rgb), 0.7);
  font-size: 16px;
  line-height: 25px;
}
[dir="ltr"] .sp-s-l-b-c-i-fav-icon-bc {
  border-right: 1px solid var(--b);
}
[dir="rtl"] .sp-s-l-b-c-i-fav-icon-bc {
  border-left: 1px solid var(--b);
}
.sp-s-l-b-c-i-sport-icon-bc {
  flex-shrink: 0;
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
  color: rgba(var(--b-sc-rgb), 0.8);
  font-size: 16px;
}
.sp-s-l-b-c-i-g-live-count-bc {
  display: flex;
  align-items: center;
  height: 20px;
  padding: 0 6px;
  text-align: center;
  border-radius: 6px;
  color: #fff;
  background: var(--oc-1);
  -webkit-margin-end: 10px;
  margin-inline-end: 10px;
  font-size: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}
.sp-s-l-b-c-i-g-live-count-bc > i {
  -webkit-margin-end: 5px;
  margin-inline-end: 5px;
  font-size: 14px;
}
.today-bets i {
  position: relative;
}
.today-bets i:after {
  font-size: 10px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-family: Roboto;
  line-height: 18px;
  letter-spacing: -1px;
}
.competition-details-section {
  border-radius: 4px;
  overflow: hidden;
}
.competition-details-section + .competition-details-section {
  margin-top: 2px;
}
.competition-details-header {
  display: flex;
  align-items: center;
  min-height: 34px;
  margin-bottom: 1px;
  padding: 5px 10px;
  background: rgba(var(--b-sc-rgb), 0.15);
  cursor: pointer;
  transition: background 0.24s;
}
.competition-details-header p {
  color: var(--b-sc);
  font-size: 12px;
  font-weight: 500;
}
.competition-details-header p:not(:only-child) {
  max-width: 48%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.competition-details-header span {
  margin: 0 8px;
  color: rgba(var(--b-sc-rgb), 0.5);
  font-size: 11px;
}
.competition-details-header.active,
.competition-details-header:hover {
  background: rgba(var(--b-sc-rgb), 0.25);
}
.competition-details-odds {
  display: flex;
}
.competition-details-info-container {
  display: flex;
  align-items: center;
  height: 24px;
  margin-top: 1px;
  padding: 0 5px;
  color: rgba(var(--b-sc-rgb), 0.5);
  background: rgba(var(--b-sc-rgb), 0.1);
  cursor: pointer;
  font-size: 11px;
}
.competition-details-info-container i {
  flex-shrink: 0;
  color: rgba(var(--b-sc-rgb), 0.8);
}
.competition-details-info-container b {
  flex-shrink: 0;
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
  cursor: pointer;
  font-weight: 400;
}
.competition-details-info-time {
  flex: auto;
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
}
.competition-details-more-icon,
.competition-details-more-icon + .notification-loader {
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
  -webkit-padding-end: 10px;
  padding-inline-end: 10px;
  cursor: pointer;
}
[dir="ltr"]
  .competition-details-more-icon
  + .notification-loader:not(:only-child),
[dir="ltr"] .competition-details-more-icon:not(:only-child) {
  box-shadow: 1px 0 0 0 rgba(var(--b-sc-rgb), 0.1);
}
[dir="rtl"]
  .competition-details-more-icon
  + .notification-loader:not(:only-child),
[dir="rtl"] .competition-details-more-icon:not(:only-child) {
  box-shadow: -1px 0 0 0 rgba(var(--b-sc-rgb), 0.1);
}
.multi-column-toggle-title {
  flex-shrink: 0;
  margin: 0 5px;
  color: rgba(var(--b-sc-rgb), 0.8);
  font-size: 12px;
}
.current-day-1:after {
  content: "1";
}
.current-day-2:after {
  content: "2";
}
.current-day-3:after {
  content: "3";
}
.current-day-4:after {
  content: "4";
}
.current-day-5:after {
  content: "5";
}
.current-day-6:after {
  content: "6";
}
.current-day-7:after {
  content: "7";
}
.current-day-8:after {
  content: "8";
}
.current-day-9:after {
  content: "9";
}
.current-day-10:after {
  content: "10";
}
.current-day-11:after {
  content: "11";
}
.current-day-12:after {
  content: "12";
}
.current-day-13:after {
  content: "13";
}
.current-day-14:after {
  content: "14";
}
.current-day-15:after {
  content: "15";
}
.current-day-16:after {
  content: "16";
}
.current-day-17:after {
  content: "17";
}
.current-day-18:after {
  content: "18";
}
.current-day-19:after {
  content: "19";
}
.current-day-20:after {
  content: "20";
}
.current-day-21:after {
  content: "21";
}
.current-day-22:after {
  content: "22";
}
.current-day-23:after {
  content: "23";
}
.current-day-24:after {
  content: "24";
}
.current-day-25:after {
  content: "25";
}
.current-day-26:after {
  content: "26";
}
.current-day-27:after {
  content: "27";
}
.current-day-28:after {
  content: "28";
}
.current-day-29:after {
  content: "29";
}
.current-day-30:after {
  content: "30";
}
.current-day-31:after {
  content: "31";
}
.comp-select-row-bc {
  flex: auto;
}
.comp-select-row-bc .form-control-label-bc.inputs {
  height: 100%;
}
.comp-select-row-bc .form-control-select-bc {
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-padding-start: 10px;
  padding-inline-start: 10px;
  background: rgba(var(--b-sc-rgb), 0.05);
}
.comp-select-row-bc .form-control-icon-bc {
  font-size: 11px;
}
[dir="ltr"] .comp-select-row-bc .form-control-icon-bc {
  right: 5px;
}
[dir="rtl"] .comp-select-row-bc .form-control-icon-bc {
  left: 5px;
}
