.notification-loader .vivaro-loader svg {
  max-height: 13px;
}
.popup-holder-bc.bet-builder-top.windowed.info {
  padding: 0;
}
.popup-holder-bc.bet-builder-top.windowed.info .popup-inner-bc {
  width: 100%;
  height: 100%;
}
.popup-holder-bc.bet-builder-top.windowed.info
  .popup-inner-bc
  .status-popup-content-w-bc {
  padding: 0;
}
.bet-builder-top .status-popup-content-w-bc {
  width: 100%;
  height: 100%;
}
.betBuilder-popup {
  position: relative;
  background: #fff;
  height: 100%;
}
.betBuilder-popup iframe {
  width: 100%;
  height: calc(100% - 40px);
}
.betBuilder-popup .e-p-close-icon-bc {
  color: rgba(63, 63, 63, 0.8);
  width: 14px;
  height: 14px;
  font-size: 14px;
  line-height: 14px;
  top: 14px;
  transition: color 0.24s;
}
[dir="ltr"] .betBuilder-popup .e-p-close-icon-bc {
  right: 20px;
}
[dir="rtl"] .betBuilder-popup .e-p-close-icon-bc {
  left: 20px;
}
.betBuilder-popup .e-p-close-icon-bc:hover {
  color: #3f3f3f;
}
.betBuilder-popup .betBuilder-popup-match {
  position: relative;
  height: 40px;
  -webkit-padding-start: 20px;
  padding-inline-start: 20px;
  background: #efefef;
  box-shadow: 1px 1px 4px rgba(63, 63, 63, 0.4);
  z-index: 1;
}
.betBuilder-popup .betBuilder-popup-match span {
  display: block;
  font-size: 16px;
  font-weight: 700;
  line-height: 40px;
  color: #3f3f3f;
  max-width: 90%;
}
.betBuilder-popup.betslip-open:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
}
.betBuilder-betslip-content {
  width: 100%;
  position: absolute;
  bottom: 0;
  background: #efefef;
}
.betBuilder-betslip-content .bs-favorite-remove {
  display: flex;
  align-items: center;
  min-width: 0;
}
.betBuilder-betslip-content .betslip-remove-all {
  color: #595959;
  text-decoration: underline;
}
.betBuilder-betslip-content .bs-bet-i-b-stake-bc .bs-bet-i-b-s-i-block-bc {
  background: #ddd;
  color: #595959;
  border-color: #ddd;
}
.betBuilder-betslip-content
  .bs-bet-i-b-stake-bc
  .bs-bet-i-b-s-i-block-bc
  > span:after {
  background: #595959;
}
.betBuilder-betslip-content .bs-bet-i-b-stake-bc + .bs-numbers-bc {
  margin-top: 5px;
}
.betBuilder-betslip-content .bs-bet-i-b-title-bc.t-2,
.betBuilder-betslip-content .quickBetMsgText-bc {
  color: #595959;
}
.betBuilder-betslip-content .bet-slip-info-link {
  -webkit-margin-start: 3px;
  margin-inline-start: 3px;
}
.betBuilder-odd-remove {
  display: flex;
  align-items: center;
}
.betBuilder-odd-remove .bs-remove-all-bc {
  flex: auto;
  justify-content: flex-start;
  margin: 0;
  -webkit-margin-end: 5px;
  margin-inline-end: 5px;
  padding: 0;
}
.betBuilder-odds {
  display: flex;
  align-items: center;
  font-size: 12px;
  flex-shrink: 0;
}
.betBuilder-odds p {
  flex: auto;
  color: #595959;
}
.betBuilder-odds span {
  -webkit-margin-start: 4px;
  margin-inline-start: 4px;
  flex-shrink: 0;
  color: #faab2e;
}
.bet-builder-btn {
  height: 44px;
  font-size: 16px;
  margin-top: 8px;
  color: #000;
  background: #faab2e;
}
.bet-builder-btn:disabled {
  color: #8d8d8d;
  background: #cacaca;
}
.bet-builder-btn + .bs-info-massages {
  margin-top: 5px;
}
.sp-sub-list-bc {
  transition: all 0.24s;
}
.sp-sub-list-bc.active {
  max-height: 15000px;
}
.sp-sub-list-bc.active > .sp-s-l-head-bc .sp-s-l-h-arrow-icon-bc:before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sp-sub-list-bc.active > .sp-s-l-b-content-bc {
  opacity: 1;
  max-height: 14000px;
  pointer-events: auto;
  will-change: scroll-position;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.sp-sub-list-bc .sp-s-l-h-title-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: auto;
  height: 100%;
}
[dir="rtl"]
  .sp-sub-list-bc
  .sp-s-l-h-title-content
  .sp-s-l-h-title-bc
  + .sp-s-l-h-title-bc {
  margin-top: -4px;
}
.sp-s-l-head-bc {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sp-s-l-h-flag-bc {
  flex-shrink: 0;
}
.sp-s-l-h-flag-bc,
.sp-s-l-h-title-bc {
  -webkit-margin-end: 10px;
  margin-inline-end: 10px;
}
.sp-s-l-h-title-bc {
  text-transform: capitalize;
}
.sp-s-l-b-c-i-g-league-c-bc {
  flex-shrink: 0;
}
.sp-s-l-h-arrow-icon-bc {
  flex-shrink: 0;
  color: rgba(var(--b-sc-rgb), 0.7);
  font-size: 11px;
  transition: color 0.24s, background 0.24s;
}
.sp-s-l-h-arrow-icon-bc:before {
  display: block;
  transition: -webkit-transform 0.24s;
  transition: transform 0.24s;
  transition: transform 0.24s, -webkit-transform 0.24s;
}
.sp-s-l-b-content-bc {
  max-height: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.24s;
  overflow: hidden;
}
.sp-s-l-b-content-bc:empty {
  display: none;
}
.sp-s-l-b-c-item-bc {
  display: flex;
  align-items: center;
  height: 35px;
  -webkit-padding-start: 8px;
  padding-inline-start: 8px;
  -webkit-padding-end: 10px;
  padding-inline-end: 10px;
  background: rgba(var(--b-sc-rgb), 0.1);
  border-radius: 4px;
  transition: background 0.24s;
}
.sp-s-l-b-c-item-bc + .sp-s-l-b-c-item-bc {
  margin-top: 2px;
}
.sp-s-l-b-c-item-bc:last-child {
  margin-bottom: 3px;
}
.sp-s-l-b-c-i-title-bc {
  flex: auto;
  margin: 0 10px;
  color: rgba(var(--b-sc-rgb), 0.9);
  font-size: 13px;
}
.sp-s-l-b-c-i-title-bc + .sp-s-l-h-flag-bc {
  -webkit-margin-end: 5px;
  margin-inline-end: 5px;
}
.sp-s-l-b-c-i-g-count-bc {
  flex-shrink: 0;
  color: rgba(var(--b-sc-rgb), 0.7);
  font-size: 13px;
  min-width: 15px;
  text-align: end;
}
.competition-ico-arr-bc {
  flex-shrink: 0;
  width: 11px;
  -webkit-margin-start: 12px;
  margin-inline-start: 12px;
  color: rgba(var(--b-sc-rgb), 0.6);
  font-size: 11px;
}
[dir="rtl"] .competition-ico-arr-bc:before {
  content: "оЊ”";
}
.betBuilder-betslip-content {
  padding: 5px 20px 8px;
}
.betBuilder-betslip-content .bs-bet-i-b-stake-bc {
  margin-top: 4px;
}
.betBuilder-betslip-content .competition-header-bc {
  padding: 0;
  background: none;
}
.betBuilder-betslip-content .competition-title-bc {
  font-size: 14px;
  font-weight: 600;
  color: #4d4d4d;
}
.betBuilder-betslip-content .competition-wrapper-bc {
  box-shadow: none;
}
.betBuilder-betslip-content .competition-icon-arrow-bc {
  color: #4d4d4d;
}
.betBuilder-betslip-content .bs-numbers-bc {
  background: #c2c2c2;
}
.betBuilder-betslip-content .bs-numbers-bc .bs-number-button-bc.i-1 {
  background: #eee;
  color: #595959;
}
.betBuilder-betslip-content .bs-numbers-bc .bs-number-button-bc.i-2 {
  background: #ddd;
  color: #595959;
}
.betBuilder-betslip-content .bs-numbers-bc .bs-number-button-bc i {
  color: #8d8d8d;
}
.betBuilder-odd-remove {
  border-top: 1px solid #d8d8d8;
  padding-top: 8px;
}
.betBuilder-text {
  color: #595959;
  font-size: 14px;
  height: 60px;
  will-change: scroll-position;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.current-day-1:after {
  content: "1";
}
.current-day-2:after {
  content: "2";
}
.current-day-3:after {
  content: "3";
}
.current-day-4:after {
  content: "4";
}
.current-day-5:after {
  content: "5";
}
.current-day-6:after {
  content: "6";
}
.current-day-7:after {
  content: "7";
}
.current-day-8:after {
  content: "8";
}
.current-day-9:after {
  content: "9";
}
.current-day-10:after {
  content: "10";
}
.current-day-11:after {
  content: "11";
}
.current-day-12:after {
  content: "12";
}
.current-day-13:after {
  content: "13";
}
.current-day-14:after {
  content: "14";
}
.current-day-15:after {
  content: "15";
}
.current-day-16:after {
  content: "16";
}
.current-day-17:after {
  content: "17";
}
.current-day-18:after {
  content: "18";
}
.current-day-19:after {
  content: "19";
}
.current-day-20:after {
  content: "20";
}
.current-day-21:after {
  content: "21";
}
.current-day-22:after {
  content: "22";
}
.current-day-23:after {
  content: "23";
}
.current-day-24:after {
  content: "24";
}
.current-day-25:after {
  content: "25";
}
.current-day-26:after {
  content: "26";
}
.current-day-27:after {
  content: "27";
}
.current-day-28:after {
  content: "28";
}
.current-day-29:after {
  content: "29";
}
.current-day-30:after {
  content: "30";
}
.current-day-31:after {
  content: "31";
}
.sp-sub-list-bc {
  max-height: 36px;
  background: rgba(var(--b-sc-rgb), 0.1);
}
.sp-sub-list-bc + .sp-sub-list-bc {
  margin-top: 2px;
}
.sp-s-l-head-bc {
  height: 36px;
  padding: 0 10px;
  border-radius: 4px;
}
.sp-s-l-h-title-bc {
  font-size: 14px;
  color: rgba(var(--b-sc-rgb), 0.9);
}
.sp-s-l-b-c-i-g-league-c-bc {
  color: rgba(var(--b-sc-rgb), 0.75);
  font-size: 13px;
}
.sp-s-l-h-arrow-icon-bc {
  -webkit-margin-start: 12px;
  margin-inline-start: 12px;
}
.sp-s-l-b-content-bc {
  padding: 0 5px 3px;
  border-radius: 4px;
}
