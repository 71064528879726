.skill-games-page-bc {
  display: grid;
  grid-gap: 10px;
}
.current-day-1:after {
  content: "1";
}
.current-day-2:after {
  content: "2";
}
.current-day-3:after {
  content: "3";
}
.current-day-4:after {
  content: "4";
}
.current-day-5:after {
  content: "5";
}
.current-day-6:after {
  content: "6";
}
.current-day-7:after {
  content: "7";
}
.current-day-8:after {
  content: "8";
}
.current-day-9:after {
  content: "9";
}
.current-day-10:after {
  content: "10";
}
.current-day-11:after {
  content: "11";
}
.current-day-12:after {
  content: "12";
}
.current-day-13:after {
  content: "13";
}
.current-day-14:after {
  content: "14";
}
.current-day-15:after {
  content: "15";
}
.current-day-16:after {
  content: "16";
}
.current-day-17:after {
  content: "17";
}
.current-day-18:after {
  content: "18";
}
.current-day-19:after {
  content: "19";
}
.current-day-20:after {
  content: "20";
}
.current-day-21:after {
  content: "21";
}
.current-day-22:after {
  content: "22";
}
.current-day-23:after {
  content: "23";
}
.current-day-24:after {
  content: "24";
}
.current-day-25:after {
  content: "25";
}
.current-day-26:after {
  content: "26";
}
.current-day-27:after {
  content: "27";
}
.current-day-28:after {
  content: "28";
}
.current-day-29:after {
  content: "29";
}
.current-day-30:after {
  content: "30";
}
.current-day-31:after {
  content: "31";
}
.skill-games .casino-horizontal-sl-list-bc {
  margin-top: 20px;
}
.skill-games .casino-horizontal-sl-list-bc + .hm-row-bc.has-slider {
  position: absolute;
  left: 0;
  width: 100%;
}
.skill-games
  .casino-horizontal-sl-list-bc
  + .hm-row-bc.has-slider
  ~ .hm-row-bc {
  max-width: 1670px;
  -webkit-margin-start: auto;
  margin-inline-start: auto;
  -webkit-margin-end: auto;
  margin-inline-end: auto;
}
.skill-games-page-bc {
  grid-template-columns: repeat(4, 1fr);
  padding: 20px 4%;
}
