.redirect_container {
  width: 100%;
  height: 60px;
  display: flex;
  position: fixed;
  z-index: 10;
  background-color: black;
}

.custom-text-bg {
  display: flex;
  justify-content: center;
  height: 60px;
}

.custom-text {
  text-align: center;
  font-size: 14px;
  color: #ffffffb3;
  margin-right: 20px;
}
